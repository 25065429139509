import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { Form } from "react-bootstrap";
import { useState } from "react";

const colorPalette = [
  "#C6CB8D",
  "#858862",
  "#ABC4B2",
  "#587B87",
  "#9CDFE3",
  "#FF8C33",
  "#33FFF4",
  "#9DFF33",
  "#FF3333",
  "#3377FF",
  "#FF7F50",
  "#FFD700",
  "#8A2BE2",
  "#D2691E",
  "#00FA9A",
];

const ProductWiseEnergyConsumption = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
}) => {
  const [locations, setLocations] = useState([""]);
  const [quarters, setQuarters] = useState([""]);
  const [view, setView] = useState("time");
  const [selection, setSelection] = useState("Q1");
  const [colors, setColors] = useState([]);
  const [data, setData] = useState({
    time: {
      "Location 1": {
        groundWater: [2000, 1800, 1600, 1400],
        normalWater: [1500, 1300, 1200, 1000],
        hardWater: [500, 600, 700, 800],
      },
      "Location 2": {
        groundWater: [1900, 1700, 1500, 1300],
        normalWater: [1600, 1400, 1200, 1100],
        hardWater: [600, 700, 800, 900],
      },
      "Location 3": {
        groundWater: [1800, 1600, 1400, 1200],
        normalWater: [1700, 1500, 1300, 1100],
        hardWater: [700, 800, 900, 1000],
      },
      "Location 4": {
        groundWater: [1700, 1500, 1300, 1100],
        normalWater: [1800, 1600, 1400, 1200],
        hardWater: [800, 900, 1000, 1100],
      },
      "Location 5": {
        groundWater: [1600, 1400, 1200, 1000],
        normalWater: [1900, 1700, 1500, 1300],
        hardWater: [900, 1000, 1100, 1200],
      },
    },
    location: {
      Q1: {
        groundWater: [2000, 1500, 1000, 1200, 1300],
        normalWater: [1500, 1200, 1100, 1000, 1400],
        hardWater: [500, 800, 900, 800, 1200],
      },
      Q2: {
        groundWater: [1800, 1600, 1100, 1200, 1300],
        normalWater: [1600, 1300, 1200, 1100, 1400],
        hardWater: [600, 700, 800, 600, 1100],
      },
      Q3: {
        groundWater: [1900, 1700, 1200, 1300, 1400],
        normalWater: [1700, 1400, 1300, 1200, 1500],
        hardWater: [700, 800, 900, 700, 1300],
      },
      Q4: {
        groundWater: [2000, 1800, 1300, 1400, 1500],
        normalWater: [1800, 1500, 1400, 1300, 1600],
        hardWater: [800, 900, 1000, 800, 1400],
      },
    },
    location: {
      "Location 1": {
        groundWater: [2000, 1800, 1600, 1400],
        normalWater: [1500, 1300, 1200, 1000],
        hardWater: [500, 600, 700, 800],
      },
      "Location 2": {
        groundWater: [1900, 1700, 1500, 1300],
        normalWater: [1600, 1400, 1200, 1100],
        hardWater: [600, 700, 800, 900],
      },
      "Location 3": {
        groundWater: [1800, 1600, 1400, 1200],
        normalWater: [1700, 1500, 1300, 1100],
        hardWater: [700, 800, 900, 1000],
      },
      "Location 4": {
        groundWater: [1700, 1500, 1300, 1100],
        normalWater: [1800, 1600, 1400, 1200],
        hardWater: [800, 900, 1000, 1100],
      },
      "Location 5": {
        groundWater: [1600, 1400, 1200, 1000],
        normalWater: [1900, 1700, 1500, 1300],
        hardWater: [900, 1000, 1100, 1200],
      },
    },
  });
  const formatValue = (value) => {
    if (value >= 1e6) {
      return `${(value / 1e6).toFixed(0)}M`; // Format millions
    } else if (value >= 1e3) {
      return `${(value / 1e3).toFixed(0)}K`; // Format thousands
    } else {
      return Math.round(value); // Format normal numbers
    }
  };
  const [chartOptions, setChartOptions] = useState({
    chart: {
      height: 350,
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false, // If it's a vertical bar chart (set to true for horizontal)
        columnWidth: "25%", // Adjust the percentage to reduce column width
        borderRadius: 10, // Apply border radius (supported in newer versions)
      },
    },
    stroke: {
      curve: "smooth",
      width: 4,
    },
    markers: {
      size: 6, // Size of the circle around the data points
      shape: "circle", // Shape of the markers
      strokeColor: "transparent", // Color of the marker border
      strokeWidth: 2, // Thickness of the marker border
      fillColor: "#fff", // Background color of the marker
      hover: {
        size: 8, // Size of the marker when hovered
      },
    },
    xaxis: {
      categories: view === "time" ? quarters : locations,
      type: "category",
      tickPlacement: "between", // This helps in spacing the tick
      labels: {
        style: {
          fontSize: "8px",
          colors: "#000000",
        },
        trim: false,
      },
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      title: {
        text: "---------- Energy in GJ -----------",
      },
      labels: {
        formatter: function (value) {
          return formatValue(value);
        },
        style: {
          fontSize: "12px",
          colors: "#A9A9A9",
          fontWeight: 400,
        },
      },
    },
    fill: {
      type: "none",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy",
      },
    },
    legend: {
      show: false,
    },
  });
  const [chartSeries, setChartSeries] = useState([
    {
      name: "Ground Water",
      data:
        view === "time"
          ? data.time[selection]?.groundWater || []
          : data.location[selection]?.groundWater || [],
    },
    {
      name: "Normal Water",
      data:
        view === "time"
          ? data.time[selection]?.normalWater || []
          : data.location[selection]?.normalWater || [],
    },
    {
      name: "Hard Water",
      data:
        view === "time"
          ? data.time[selection]?.hardWater || []
          : data.location[selection]?.hardWater || [],
    },
  ]);

  const handleViewChange = (e) => {
    const newView = e.target.value;
    setView(newView);
    setSelection(newView === "time" ? locations[0] : quarters[0]);
  };

  const handleSelectionChange = (e) => {
    setSelection(e.target.value);
  };

  const location = 2;

  function convertMixedData(mixedArray) {
    return mixedArray.map((data) => {
      if (Array.isArray(data.answer) && Array.isArray(data.answer[0])) {
        const flattenedAnswer = data.answer.flat();
        const summedValue = flattenedAnswer.reduce(
          (sum, value) => sum + (parseFloat(value) || 0),
          0
        );

        return {
          questionId: data.questionId,
          sourceId: data.sourceId,
          answer: {
            process: 1,
            readingValue: summedValue.toString(),
            unit: "KG",
          },
          title: data.title,
          question_details: data.question_details,
          formDate: data.formDate,
          toDate: data.toDate,
        };
      } else {
        return {
          ...data,
          answer: {
            ...data.answer,
            readingValue: data?.answer?.readingValue || "0",
          },
        };
      }
    });
  }
  useEffect(() => {
    const dataType =
      view === "time" ? data.time[selection] : data.location[selection];

    const waterTypes = dataType ? Object.keys(dataType) : [];

    const parameters = dataType ? Object.keys(dataType) : [];

    const colors = parameters.map(
      (_, index) => colorPalette[index % colorPalette.length]
    );
    setColors(colors);

    const newChartSeries = waterTypes.map((waterType) => ({
      name: waterType
        .split(/(?=[A-Z])/)
        .join(" ")
        .replace(/\b\w/g, (l) => l.toUpperCase()),
      data: dataType?.[waterType] || [],
    }));

    setChartSeries(newChartSeries);
    setChartOptions((prevState) => {
      return {
        ...prevState,
        xaxis: {
          categories: view === "time" ? quarters : locations,
          type: "category",
          labels: {
            style: {
              fontSize: "8px",
              colors: "#000000",
            },
            trim: false,
          },
        },
        colors: colors,
      };
    });
  }, [view, selection, data]);
  useEffect(() => {
    const valuesArray = locationOption
      ? locationOption.map((item) => item.unitCode || item.value)
      : [];

    const transformedKeys = Object.keys(timePeriods).map((key) =>
      key
    );

    setSelection(view === "time" ? valuesArray[0] : transformedKeys[0]);
    setQuarters(transformedKeys);
    setLocations(valuesArray);

    const summary = {
      time: {},
      location: {},
    };

    locationOption.forEach((location) => {
      transformedKeys.forEach((quarter) => {
        summary.location[quarter] = {
          "Diesel consumption": new Array(locationOption.length).fill(0),
          "LPG consumption": new Array(locationOption.length).fill(0),
          "Petrol consumption": new Array(locationOption.length).fill(0),
          "CNG consumption": new Array(locationOption.length).fill(0),
          "PNG consumption": new Array(locationOption.length).fill(0),
          "Electricity Power plant (Captive Power Plant - Natural Gas)":
            new Array(locationOption.length).fill(0),
          "Electricity consumption through DG": new Array(
            locationOption.length
          ).fill(0),
          "Total electricity consumption from Renewable energy (via PPA)":
            new Array(locationOption.length).fill(0),
          "Total electricity consumption from Renewable energy (rooftop solar)":
            new Array(locationOption.length).fill(0),
          "Total electricity consumption (GRID electricity)": new Array(
            locationOption.length
          ).fill(0),
        };
      });
    });

    transformedKeys.forEach((quarter) => {
      locationOption.forEach((location) => {
        summary.time[location.unitCode] = {
          "Diesel consumption": new Array(transformedKeys.length).fill(0),
          "LPG consumption": new Array(transformedKeys.length).fill(0),
          "Petrol consumption": new Array(transformedKeys.length).fill(0),
          "CNG consumption": new Array(transformedKeys.length).fill(0),
          "PNG consumption": new Array(transformedKeys.length).fill(0),
          "Electricity Power plant (Captive Power Plant - Natural Gas)":
            new Array(transformedKeys.length).fill(0),
          "Electricity consumption through DG": new Array(
            transformedKeys.length
          ).fill(0),
          "Total electricity consumption from Renewable energy (via PPA)":
            new Array(transformedKeys.length).fill(0),
          "Total electricity consumption from Renewable energy (rooftop solar)":
            new Array(transformedKeys.length).fill(0),
          "Total electricity consumption (GRID electricity)": new Array(
            transformedKeys.length
          ).fill(0),
        };
      });
    });

    const filteredData = Array.isArray(graphData)
      ? graphData.filter(
        (item) =>
          item.questionId === 289 ||
          item.questionId === 292 ||
          item.questionId === 293 ||
          item.questionId === 294 ||
          item.questionId === 295 ||
          item.questionId === 426 ||
          item.questionId === 429 ||
          item.questionId === 430 ||
          item.questionId === 468
      ) : [];
    const convertedData = convertMixedData(filteredData);
    const timeKey = [];
    const locationKey = [];

    for (const period in summary.location) {
      timeKey.push(period);
    }

    for (const period in summary.time) {
      locationKey.push(period);
    }

    for (const location in summary.time) {
      const data = summary.time[location];
      for (const key in data) {
        for (let k = 0; k < summary.time[location][key].length; k++) {
          let time = timeKey[k];
          const obj = locationOption.find((item) => item.unitCode === location);
          const lowerCaseKey = time;
          const formDate = timePeriods[lowerCaseKey];
          const filterData = convertedData.find(
            (item) =>
              item.title === key &&
              item.formDate === formDate &&
              item.sourceId === obj.id
          );
          summary.time[location][key][k] =
            Number(filterData?.answer?.readingValue) || 0;
        }
      }
    }
    for (const time in summary.location) {
      const data = summary.location[time];
      for (const key in data) {
        for (let k = 0; k < summary.location[time][key].length; k++) {
          let location = locationKey[k];
          const obj = locationOption.find((item) => item.unitCode === location);
          const lowerCaseKey = time;
          const formDate = timePeriods[lowerCaseKey];
          const filterData = convertedData.find(
            (item) =>
              item.title === key &&
              item.formDate === formDate &&
              item.sourceId === obj.id
          );
          summary.location[time][key][k] =
            Number(filterData?.answer?.readingValue) || 0;
        }
      }
    }

    setData({
      time: summary.time,
      location: summary.location,
    });
  }, [locationOption, timePeriods, graphData]);

  const nameMap = {
    "Diesel Consumption": "Diesel",
    "L P G Consumption": "LPG",
    "Petrol Consumption": "Petrol",
    "C N G Consumption": "CNG",
    "P N G Consumption": "PNG",
    "Electricity  Power Plant ( Captive  Power  Plant -  Natural  Gas)":
      "Natural Gas",
    "Electricity Consumption Through  D G": "DG",
    "Total Electricity Consumption From  Renewable Energy (Via  P P A)": "PPA",
    "Total Electricity Consumption From  Renewable Energy (Rooftop Solar)":
      "Rooftop Solar",
    "Total Electricity Consumption ( G R I D Electricity)": "GRID",

    // Add more mappings as needed
  };

  return (
    <div className="container" style={{ height: "100%", width: "100%" }}>
      <div
        className="header"
        style={{ height: "10%", width: "100%", marginLeft: "0%" }}
      >
        <div className="title" style={{ marginLeft: "0%" }}>
          Product Wise Energy Consumption
        </div>
      </div>
      <div
        className="chart-container"
        style={{
          marginTop: "-2%",
          height: "90%",
          width: "100%",
          marginLeft: "0%",
        }}
      >
        {location >= 1 && (
          <div style={{ height: "100%", width: "100%", marginLeft: "0%" }}>
            <div
              className="radio-group horizontal"
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "1%",
                height: "5%",
                width: "100%",
                marginLeft: "0%",
              }}
            >
              <div style={{ width: "20%" }}>
                <Form.Check
                  type="radio"
                  style={{ marginRight: "5%" }}
                  label="Time"
                  value="time"
                  checked={view === "time"}
                  onChange={handleViewChange}
                />
              </div>
              <div style={{ width: "20%" }}>
                <Form.Check
                  type="radio"
                  label="Location"
                  value="location"
                  checked={view === "location"}
                  onChange={handleViewChange}
                />
              </div>
            </div>
            <div
              className="radio-group horizontal"
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                overflow: "auto",
                marginLeft: "0%",
                height: "5%",
                width: "100%",
                marginTop: "1%",
              }}
            >
              {view === "time"
                ? locations.map((q) => (
                  <div key={q} style={{ width: "20%" }}>
                    <Form.Check
                      type="radio"
                      style={{ marginRight: "5%" }}
                      label={q}
                      value={q}
                      checked={selection === q}
                      onChange={handleSelectionChange}
                    />
                  </div>
                ))
                : quarters.map((loc) => (
                  <div style={{ width: "20%" }}>
                    <Form.Check
                      key={loc}
                      type="radio"
                      label={loc}
                      value={loc}
                      style={{ marginRight: "5%" }}
                      checked={selection === loc}
                      onChange={handleSelectionChange}
                    />
                  </div>
                ))}
            </div>
            <div
              className="chart-container"
              style={{ marginLeft: "0%", height: "75%", width: "100%" }}
            >
              <Chart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height={"100%"}
                width={"100%"}
              />
            </div>
            <div
              className="legend-container"
              style={{
                width: "100%",
                marginLeft: "0%",
                height: "11%",
                overflow: "auto",
              }}
            >
              {chartOptions.colors &&
                chartOptions.colors.length > 0 &&
                chartOptions.colors.map((color, index) => {
                  // Log chartSeries[index] to console

                  return (
                    <div
                      className="legend-item"
                      style={{
                        width: "45%",
                        height: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          width: "40%",
                          height: "100%",
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "center",
                          marginTop: "5%",
                        }}
                      >
                        <div
                          className="legend-color-box"
                          style={{
                            backgroundColor: color,
                            height: "10px",
                            width: "10px",
                            borderRadius: "50%",
                          }}
                        ></div>
                      </div>

                      <div style={{ width: "60%", height: "100%" }}>
                        <div
                          className="legend-text"
                          style={{
                            display: "flex",
                            height: "100%",
                            alignItems: "flex-start",
                            justifyContent: "center",
                          }}
                        >
                          {nameMap[chartSeries[index]?.name] || " "}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductWiseEnergyConsumption;
