import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import deleteIcon from "../img/deleteIcon.png";
import editIcon from "../img/editIcon.png";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import TrainingModal from "./TrainingModal";
import { Modal, Button, Tabs, Tab, Table } from "react-bootstrap";
import NoDataFound from "../../../img/no.png";
import Toast from "react-bootstrap/Toast";
import AddTrainee from "./AddTrainee";
import copyIcon from "../img/CopyIcon.png";
import AddIcon from "../img/AddTraineeIcon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faTrash } from "@fortawesome/free-solid-svg-icons";

const TrainingTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [trainings, setTrainings] = useState([]);
  const [actionId, setActionId] = useState(null);
  const [mode, setMode] = useState(null);
  const [actionModalShow, setActionModalShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showAddTraineeModal, setShowAddTraineeModal] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false);
  const [showUserData, setShowUserData] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [financialYear, setFinancialYear] = useState([]);
  const [users, setUsers] = useState([]);

  const [financialYearId, setFinancialYearId] = useState(0);
  const [key, setKey] = useState("register"); // Default active tab

  // Helper function to render table rows
  const renderTableRows = (rows) => {
    return rows.map((user) => (
      <tr key={user.id}>
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>{user.emailId}</td>
        <td>{user.mobileNumber}</td>
        <td>{user.employeeId}</td>
        <td>{user.gender}</td>
      </tr>
    ));
  };

  const handleActionClose = () => {
    setActionId(null);
    setActionModalShow(false);
    setShowAddTraineeModal(false);
  };

  const handleQRActionClose = () => {
    setActionId(null);
    setShowQRModal(false);
  };

  const getUserData = async (id) => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTraineeUserData`,
      {},
      { id },
      "GET"
    );

    if (isSuccess) {
      setUsers(data?.data);
    }
  };

  const getTrainingData = async (fId) => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTrainingData`,
      {},
      { financialYearId: fId || financialYearId },
      "GET"
    );

    if (isSuccess) {
      const tmpData =
        data?.data && data?.data.length ? data?.data.reverse() : [];
      const formattedData = tmpData.map((item) => ({
        item,
        id: item.id,
        fromDate: `${new Date(item.fromDate).toLocaleDateString()}}`,
        toDate: `${new Date(item.toDate).toLocaleDateString()}`,
        fromTime: `${item.fromTime} `,
        toTime: `${item.toTime}`,
        trainer: item.trainers,
        title: item.trainingTitle,
        trainingLink: item.trainingLink,
        mode: item.modeOfTraining,
        mappingUser: item.userId,
        targetAudience: JSON.parse(item.targetAudience),
      }));

      setTrainings(formattedData);
    }
  };

  const handleActionAPI = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}deleteTrainingData`,
      {},
      { trainingId: actionId, status: mode === "Delete" ? 0 : 2 },
      "POST"
    );

    if (isSuccess) {
      handleActionClose();
      getTrainingData();
    }
  };

  const handleSaveTrainee = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}addTraineeOrInviteTrainee`,
      {},
      {
        trainingId: actionId,
        traineeEmails: selectedEmails.map((item) => item.email),
      },
      "POST"
    );

    if (isSuccess) {
      handleActionClose();
    }
  };

  const handleDelete = (id) => {
    setActionId(id);
    setActionModalShow(true);
  };

  const handleCloseModal = () => {
    getTrainingData();
    setShowModal(false);
    setActionId(null);
    setEditData(null);
  };

  const handleEdit = (id, training) => {
    setShowModal(true);
    setActionId(id);
    setEditData(training.item);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCopyLink = (link) => {
    if (link) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          setShowToast(true);
        })
        .catch((err) => {
          console.error("Failed to copy link: ", err);
        });
    } else {
      alert("No link available to copy.");
    }
  };
  const formatTime = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM/PM format
    return `${formattedHours}:${
      minutes < 10 ? "0" + minutes : minutes
    } ${period}`;
  };
  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {
      setFinancialYear(data.data);
      if (data?.data?.length) {
        setFinancialYearId(data.data[data?.data?.length - 1].id);
        getTrainingData(data.data[data?.data?.length - 1].id);
      }
    }
  };

  useEffect(() => {
    getFinancialYear();
  }, []);

  useEffect(() => {
    getTrainingData();
  }, [financialYearId]);

  const renderTable = () => {
    return (
      <div
        style={{
          ...tableContainerStyle,
          padding: "20px",
          border: "1px solid white",
          backgroundColor: "white",
        }}
      >
        <div>
          <div style={searchContainerStyle}>
            <input
              type="text"
              className="form-control"
              placeholder="Search topic name"
              style={{ borderRadius: "12px", marginBottom: "1em" }}
            />
          </div>
        </div>
        {trainings && trainings.length ? (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th
                  style={{ ...tableHeaderStyle, width: "5%", fontSize: "16px" }}
                >
                  {" "}
                  From Date
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    width: "7% ",
                    fontSize: "16px",
                  }}
                >
                  To Date
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    width: "6%",
                    fontSize: "16px",
                  }}
                >
                  From Time
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    width: "6%",
                    fontSize: "16px",
                  }}
                >
                  To Time
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    width: "12%",
                    fontSize: "16px",
                  }}
                >
                  Trainer Name
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    width: "13%",
                    fontSize: "16px",
                  }}
                >
                  Training Title
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    width: "10%",
                    fontSize: "16px",
                  }}
                >
                  Mode
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    width: "17%",
                    fontSize: "16px",
                  }}
                >
                  Audience
                </th>
                <th
                  style={{ ...tableHeaderStyle, fontSize: "16px" }}
                  className="text-left"
                >
                  Operation
                </th>
              </tr>
            </thead>
            <tbody>
              {trainings.map((item) => (
                <tr
                  key={item.fromDate}
                  style={{
                    backgroundColor: item.id % 2 === 0 ? "white" : "white",
                    borderBottom: "1px solid #83BBD5",
                  }}
                >
                  {/* <td style={tableDataStyle}>
                    {String(item.fromDate).padStart(2, "0")}
                  </td> */}
                  <td style={tableDataStyle}>{item.toDate}</td>

                  <td style={tableDataStyle}>{item.toDate}</td>
                  <td style={tableDataStyle}>{formatTime(item.fromTime)}</td>
                  <td style={tableDataStyle}>{formatTime(item.toTime)}</td>
                  <td style={tableDataStyle}>{item.trainer}</td>
                  <td style={tableDataStyle}>{item.title}</td>
                  <td style={tableDataStyle}>{item.mode}</td>
                  <td style={tableDataStyle}>
                    {item.targetAudience
                      .map((audience) => {
                        switch (audience) {
                          case "EMPLOYEES_PERMANENT":
                            return "Permanent Employees";
                          case "EMPLOYEES_TEMPORARY":
                            return "Other than Permanent Employees";
                          case "WORKERS_PERMANENT":
                            return "Permanent Workers";
                          case "WORKERS_TEMPORARY":
                            return "Other than Permanent Workers";
                          case "KMP":
                            return "KMP";
                          case "BOD":
                            return "BOD";
                          case "CUSTOMERS":
                            return "CUSTOMERS";
                          case "SUPPLIERS":
                            return "SUPPLIERS";
                          case "DISTRIBUTORS":
                            return "DISTRIBUTORS";
                          default:
                            return audience;
                        }
                      })
                      .join(", ")}
                  </td>
                  <td style={tableDataStyle} className="text-left">
                    <button
                      className=""
                      style={generateLinkStyle}
                      onClick={() => {
                        setMode("External Register");
                        setShowQRModal(true);
                        setActionId(item?.item?.registerExternalQrLink);
                      }}
                    >
                      <i
                        className="fas fa-link"
                        title="Copy External Register Link"
                      ></i>
                    </button>{" "}
                    <button
                      className=""
                      style={generateLinkStyle}
                      onClick={() => {
                        setMode("Internal Register");
                        setShowQRModal(true);
                        setActionId(item?.item?.registerInternalQrLink);
                      }}
                    >
                      <i
                        className="fas fa-link"
                        title="Copy Internal Register Link"
                      ></i>
                    </button>{" "}
                    <button
                      className=""
                      style={generateLinkStyle}
                      onClick={() => {
                        setMode("External Attendance");
                        setShowQRModal(true);
                        setActionId(item?.item?.attendenceExternalQrLink);
                      }}
                    >
                      <i
                        className="fas fa-qrcode"
                        title="Copy External Attendance Link"
                      ></i>
                    </button>{" "}
                    <button
                      className=""
                      style={generateLinkStyle}
                      onClick={() => {
                        setMode("Internal Attendance");
                        setShowQRModal(true);
                        setActionId(item?.item?.attendenceInternalQrLink);
                      }}
                    >
                      <i
                        className="fas fa-qrcode"
                        title="Copy Internal Attendance Link"
                      ></i>
                    </button>
                    <button
                      className=""
                      style={generateLinkStyle}
                      onClick={() => handleCopyLink(item?.trainingLink)}
                    >
                      <img src={copyIcon} alt="CopyLink" title="Copy Link" />
                    </button>
                    <button
                      style={generateLinkStyle}
                      onClick={() => {
                        setShowAddTraineeModal(true);
                        setActionId(item?.id);
                      }}
                    >
                      <img src={AddIcon} alt="Add" title="Add Trainee" />
                      {/* Add Trainee */}
                    </button>
                    <span
                      onClick={() => handleEdit(item.id, item)}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={editIcon}
                        alt="edit"
                        className="ms-2 me-2"
                        title="Edit"
                      />
                    </span>
                    {item.mappingUser.length ? (
                      <span
                        onClick={() => {
                          handleDelete(item.id);
                          setMode("Cancel");
                        }}
                        style={{ cursor: "pointer", color: "red" }}
                        title="Cancel Training"
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          handleDelete(item.id);
                          setMode("Delete");
                        }}
                        style={{ cursor: "pointer", color: "red" }}
                        title="Delete Training"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </span>
                    )}
                    <span
                      onClick={() => {
                        setShowUserData(true);
                        getUserData(item.id);
                      }}
                      style={{ cursor: "pointer",marginLeft:"5px" }}
                    >
                      <i className="fas fa-eye" title="View"></i>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="hstack justify-content-center">
            <img src={NoDataFound} alt="" srcSet="" />
          </div>
        )}
        <Modal
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            backdropFilter: "blur(10px)",
          }}
          show={showUserData}
          onHide={()=>setShowUserData(false)}
          size="md" 
        >
          <Modal.Header closeButton>
            <Modal.Title>Users Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              id="user-data-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="register" title="Register">
              <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                <Table striped bordered hover size="md">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Employee ID</th>
                      <th>Gender</th>
                    </tr>
                  </thead>
                  {users.attendenceUser && users.registerUser.length ? (
                    <tbody>{renderTableRows(users.registerUser)}</tbody>
                  ) : (
                    <p>No Data Found</p>
                  )}
                </Table>
                </div>
              </Tab>
              <Tab eventKey="attended" title="Attended">
              <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                <Table striped bordered hover size="md">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Employee ID</th>
                      <th>Gender</th>
                    </tr>
                  </thead>
                  {users.attendenceUser && users.attendenceUser.length ? (
                    <tbody>{renderTableRows(users.attendenceUser)}</tbody>
                  ) : (
                    <p>No Data Found</p>
                  )}
                </Table>
                </div>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={()=>setShowUserData(false)}>
              Close
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            backdropFilter: "blur(10px)",
          }}
          show={showAddTraineeModal}
          onHide={handleActionClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Guest</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddTrainee
              selectedEmails={selectedEmails}
              setSelectedEmails={setSelectedEmails}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              style={{ width: "100%" }}
              onClick={handleSaveTrainee}
            >
              Send Invite
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            backdropFilter: "blur(10px)",
          }}
          show={showQRModal}
          onHide={handleQRActionClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>{mode} QR Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              {actionId ? (
                <img src={actionId} alt="QR Code for Registration" />
              ) : (
                <p>No QR Code available</p>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="TrainingTableModal"
          style={TrainingTableModal}
          show={actionModalShow}
          onHide={handleActionClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to {mode}?</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleActionAPI}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          style={{
            position: "fixed",
            bottom: "90%",
            left: "50%",
            zIndex: 1000,
            transform: "translateX(-50%)",
            backgroundColor: "#28a745",
            color: "#fff",
          }}
        >
          <Toast.Body>Link copied to clipboard!</Toast.Body>
        </Toast>
      </div>
    );
  };

  return (
    <>
      <div style={headerContainerStyle}>
        <div style={headerTitleStyle}>Trainer Portal</div>
        <div>
          <select
            className="sector-question-select"
            value={
              financialYearId ||
              (financialYear.length > 0
                ? financialYear[financialYear.length - 1].id
                : "")
            }
            onChange={async (e) => {
              setFinancialYearId(e.target.value);
            }}
          >
            <option value={0}>Select Financial Year</option>
            {financialYear?.map((item, key) => (
              <option key={key} value={item.id}>
                {item.financial_year_value}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button style={createButtonStyle} onClick={() => setShowModal(true)}>
            Create new training
          </button>
        </div>
      </div>
      <div style={trainingContainerStyle}>
        {trainings && trainings.length ? (
          <div style={searchContainerStyle}>
            {/* <input
              type="text"
              className="form-control"
              placeholder="Search topic name"
              style={{ borderRadius: "12px" }}
            /> */}
          </div>
        ) : (
          <></>
        )}
        <div style={tableWrapperStyle}>{renderTable()}</div>
      </div>
      <TrainingModal
        show={showModal}
        handleClose={() => handleCloseModal()}
        actionId={actionId}
        editData={editData}
        financialYearId={financialYearId}
      />
    </>
  );
};

// Inline CSS styles
const headerContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  fontSize: "15px",
  marginTop: "2em",
  marginBottom: "0.5em",
};
const headerTitleStyle = {
  fontSize: "28px",
  fontWeight: "bold",
};
const createButtonStyle = {
  backgroundColor: "#3F88A5",
  color: "white",
  borderRadius: "22px",
  border: "none",
  padding: "10px 20px",
  cursor: "pointer",
};
const TrainingTableModal = {
  // height:'max-content ',
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  backdropFilter: "blur(1px)",
  // top:'25%',
};
const trainingContainerStyle = {
  // border: "1px solid #DCDCDC",
  // borderRadius: "10px",
  // padding: "20px",
};
const tableContainerStyle = {
  marginTop: "1em",
  borderRadius: "10px",
  overflow: "hidden",
};
const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
};
const tableHeaderStyle = {
  backgroundColor: "white",
  color: "#3F88A5",
  borderBottom: "2px solid #3F88A5",
  padding: "10px ",
  textAlign: "left",
};
const tableDataStyle = {
  padding: "10px",
  fontSize: "16px",
};
const editInputStyle = {
  border: "none",
  borderBottom: "1px solid #DCDCDC",
  outline: "none",
  width: "100%",
};
const generateLinkStyle = {
  backgroundColor: "transparent",
  // color: "white",
  border: "none",
  // padding: "5px 10px",
  // borderRadius: "5px",
  cursor: "pointer",
};
const searchContainerStyle = {
  display: "flex",
  justifyContent: "flex-end",
};
const tableWrapperStyle = {
  // Add this style
  overflowX: "auto",
};

export default TrainingTable;
