import React, { Component } from "react";
import config from "../../../../config/config.json";
import { apiCall } from "../../../../_services/apiCall";
import ParentExcel from "./parentexcel";

export default class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: [],
      questions: [],
      loadingQuestions: true,
      fetchingAnswers: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const financialYearId = 30;
    const frameworkIds = [48];

    try {
      // Step 1: Fetch questions
      const questionResponse = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getReportingQuestion`,
        {},
        { financialYearId, frameworkIds },
        "GET"
      );

      let questionIds = [];

      if (Array.isArray(questionResponse.data)) {
        this.setState({ questions: questionResponse.data });
        questionIds = questionResponse.data.map((question) => question.questionId);
      } else if (typeof questionResponse.data === "object" && questionResponse.data !== null) {
        const questionsArray = Object.values(questionResponse.data.data);
        questionIds = questionsArray.map((question) => question.questionId);
        this.setState({ questions: questionsArray });
      } else {
        console.error("Unexpected data format:", questionResponse.data);
      }

      if (questionIds.length === 0) {
        throw new Error("No questions found");
      }

      this.setState({ loadingQuestions: false, fetchingAnswers: true });

      for (const questionId of questionIds) {
        const answerResponse = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getReportingAnswer`,
          {},
          { financialYearId, questionId },
          "GET"
        );

        if (answerResponse.isSuccess) {
          this.setState((prevState) => ({
            answers: [...prevState.answers, ...(answerResponse.data?.answers || [])],
          }));
        }
      }

      this.setState({ fetchingAnswers: false });
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ loadingQuestions: false, fetchingAnswers: false });
    }
  }

  render() {
    const { loadingQuestions, fetchingAnswers, answers, questions } = this.state;

    if (loadingQuestions) {
      return <div>Loading questions...</div>;
    }

    return (
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Entered values</h1>
        {fetchingAnswers && (
          <div className="flex items-center space-x-2 text-blue-500">
            <div
              style={{
                width: "3rem",
                height: "3rem",
                border: "2px solid #3b82f6",
                borderTop: "2px solid transparent",
                borderRadius: "50%",
                animation: "spin 1s linear infinite",
              }}
            ></div>
          </div>
        )}
        <ParentExcel allAnswers={answers} allQuestions={questions} />
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }
}
