import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';

const downloadExcel = (processedData, columns) => {
    const worksheetData = processedData.map((item) => {
        const row = { Question: item.title.replace(/<br>/g, "\n") }; // Replace <br> with \n in question titles
        columns.forEach((col) => {
            const value = item.values[col] || "-";
            row[col] = typeof value === "string" ? value.replace(/<br>/g, "\n") : value; // Replace <br> in cell values
        });
        return row;
    });

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    // Apply Excel-compatible row breaks
    Object.keys(worksheet).forEach((key) => {
        if (worksheet[key] && worksheet[key].v) {
            worksheet[key].v = worksheet[key].v.toString().replace(/<br>/g, "\n");
            worksheet[key].t = "s"; // Ensure cell is treated as a string
        }
    });

    XLSX.writeFile(workbook, "processed_data.xlsx");
};


const formatDate = (dateString) => {
    if (!dateString) return "";
    const [yearStr, monthStr] = dateString === '2023-00' ? ['2023', '01'] : dateString.split('-');
    const year = parseInt(yearStr, 10);
    const month = parseInt(monthStr, 10) - 1;
    const safeMonth = isNaN(month) || month < 0 ? 0 : month;
    const date = new Date(year, safeMonth);
    return date.toLocaleString('default', { month: 'short', year: 'numeric' });
};

const Excel = ({ data, allQuestions }) => {
    const [processedData, setProcessedData] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        const processData = () => {
            const newData = [];
            const dateColumns = new Set();
            console.log("Total quesitons: " + data.length)
            let counter = 0;
            const uniqueIds = new Set();


            // Step 2: Process sorted data
            data.forEach((item) => {

                const { questionId, fromDate, toDate, notApplicable, answer } = item;

                let value = "";

                const currentQuestion = allQuestions?.find((question) => question.questionId === questionId);

                uniqueIds.add(currentQuestion.questionId);

                counter++;

                if (notApplicable == true) {
                    value = "NA";
                } else if (currentQuestion?.questionType === "yes_no") {
                    console.log("This is yes no question: " + answer)
                    try {
                        const parsedAnswer = JSON.parse(answer);
                        value = parsedAnswer.answer; // Normalize yes/no answers
                    } catch (error) {
                        console.error("Error parsing yes_no answer:", error);
                        value = "Invalid";
                    }
                } else if (answer) {
                    const parsedAnswer = JSON.parse(answer);
                    value = formatValue(parsedAnswer);
                }


                let questionTitle = currentQuestion.title;

                if (currentQuestion?.questionType === "tabular_question") {
                    let options = currentQuestion.details
                        .filter((detail) => !(detail.option === "one"))
                        .map((detail) => detail.option) // Extract the options
                        .reverse() // Reverse the order of options
                        .join("<br>"); // Join them with <br>

                    if (!options || options.length === 0) {
                        questionTitle = currentQuestion.title;
                    } else {
                        console.log("Last options:  " + options);

                        questionTitle = options + "<br>" + "<b>" + currentQuestion.title + "<b/>";
                    }
                }


                const columnDate = fromDate === '2023-00' ? '2023-01' : fromDate;
                const formattedDate = formatDate(columnDate);

                dateColumns.add(formattedDate);

                // Check if question already exists in newData
                let existingQuestion = newData.find((q) => q.questionId === questionId);
                if (!existingQuestion) {
                    existingQuestion = {
                        questionId,
                        title: questionTitle,
                        values: {},
                        moduleName: item.moduleName, // Attach moduleName for sorting
                    };
                    newData.push(existingQuestion); // Add new question to the array
                }

                existingQuestion.values[formattedDate] = value;
                //console.log("Outgoing : " + counter)
            });

            counter = 0;
            console.log("Unique id question are: " + uniqueIds.size)

            const mandatedDates = [
                "2024-04", "2024-05", "2024-06",
                "2024-07", "2024-08", "2024-09",
                "2024-10", "2024-11", "2024-12"
            ];

            const dateSet = new Set(mandatedDates);
            data.forEach((a) => dateSet.add(a.fromDate));
            const columns = Array.from(dateSet).sort();

            mandatedDates.forEach((md) => {
                dateColumns.add(formatDate(md));
            });

            const sortedColumns = Array.from(dateColumns).sort((a, b) => new Date(a) - new Date(b));
            setProcessedData(newData); // Set processedData as an array
            setColumns(sortedColumns);
        };

        processData();
    }, [data]);


    return (
        <div style={{ overflowX: 'auto', padding: '16px', background: '#f9fafc', borderRadius: '12px' }}>
            <button
                onClick={() => downloadExcel(processedData, columns)}
                disabled={processedData.length === 0 || columns.length === 0} // Disable button until data is ready
                style={{
                    marginBottom: '12px',
                    padding: '8px 16px',
                    background: processedData.length === 0 ? '#ccc' : '#2a9d8f',
                    color: '#ffffff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                }}
            >
                Download Excel
            </button>


            <table style={{ borderCollapse: 'collapse', width: '100%', fontFamily: 'Roboto, sans-serif' }}>
                <thead>
                    <tr>
                        <th style={headerStyle}>#</th> {/* Add a new header for index */}
                        <th style={headerStyle}>Question</th>
                        {columns.map((col) => (
                            <th key={col} style={headerStyle}>
                                {col}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {processedData.reduce((acc, questionData, index) => {
                        const previousModule =
                            index > 0 ? processedData[index - 1].moduleName : null;

                        // Add a module row if the module changes
                        if (questionData.moduleName !== previousModule) {
                            acc.push(
                                <tr key={`${questionData.moduleName}-header`} style={{ background: '#f1f5f9' }}>
                                    <td colSpan={columns.length + 2} style={{
                                        ...cellStyle,
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        color: '#2a4c32',
                                        textAlign: 'center',
                                        background: '#d8f3dc',
                                    }}>
                                        {questionData.moduleName}
                                    </td>
                                </tr>
                            );
                        }

                        // Add the question row
                        acc.push(
                            <tr key={questionData.questionId}>
                                <td style={{ ...cellStyle, textAlign: 'center', fontWeight: 'bold' }}>{index + 1}</td>
                                <td style={{ ...cellStyle, color: '#1d3b1d', fontWeight: 'bold', width: "20vw" }}
                                    dangerouslySetInnerHTML={{
                                        __html: questionData.title || '-',
                                    }}>
                                </td>
                                {columns.map((col) => (
                                    <td
                                        key={col}
                                        style={{
                                            ...cellStyle,
                                            background: '#fff',
                                            color: '#000000',
                                            textAlign: 'right',
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: questionData.values[col] ?? '-',
                                        }}
                                    ></td>
                                ))}
                            </tr>
                        );

                        return acc;
                    }, [])}
                </tbody>
            </table>
        </div>
    );
};

const formatValue = (data) => {
    if (Array.isArray(data)) {
        return data
            .map((item) => {
                if (Array.isArray(item)) {
                    return formatValue(item); // Recursively handle nested arrays
                } else if (item === null || item === undefined || item === '') {
                    return '-'; // Ensure empty items are represented as blank lines
                } else {
                    console.log("Before checking whether tabular is anumber:  " + item)
                    const parsed = parseFloat(item); // Try to parse the item to a number
                    if (!isNaN(parsed)) {
                        return Math.trunc(parsed); // If parsing succeeds, truncate decimals
                    }
                    return item; // Otherwise, return the original item
                }
            })
            .join('<br>'); // Join items with newlines
    } else if (typeof data === 'object') {
        if (data.hasOwnProperty('readingValue')) {
            //Remove comma
            const value = data.readingValue.replace(/,/g, '')
            const parsed = parseFloat(value); // Try to parse the item to a number
            if (!isNaN(parsed)) {
                return Math.trunc(parsed); // If parsing succeeds, truncate decimals
            }
            return value; // Otherwise, return the original item
        } else {
            return data; // Ignore objects without `readingValue`
        }
    } else {
        console.log("Data section invoked")
        return data; // Return primitive values directly
    }
};

// Enhanced Styles
const headerStyle = {
    border: '1px solid #d3d3d3',
    padding: '12px',
    background: 'linear-gradient(90deg, #edf9f0, #e0f2eb)',
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#2a4c32',
};

const cellStyle = {
    border: '1px solid #d3d3d3',
    padding: '10px',
    textAlign: 'left',
    color: '#3c6e47',
    background: '#fff',
};

export default Excel;
