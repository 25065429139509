import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import QualitativeQuestionType from "../../Company Sub Admin/Component/Sector Questions/QualitativeQuestionType";
import QuantitativeTrendsType from "../../Company Sub Admin/Component/Sector Questions/QuantitativeTrendsType";
import "./AuditTabs.css"; // We'll define some CSS later
import QualitativeAnswer from "./QualitativeAnswer";
import { TabularAnswer } from "./TabularAnswer";
import YesNoAnswer from "./YesNoAnswer";

const AuditTabs = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("accepted");
  const [auditData, setAuditData] = useState([]);
  const [financialYearId, setFinancialYearId] = useState();
  const [selectedYear, setSelectedYear] = useState("");
  const [financialYear, setFinancialYear] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [timePeriods, setTimePeriods] = useState();
  const [item, setItem] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const isMounted = useRef(true);
  const [frequency, setFrequency] = useState(null);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [filteredAuditData, setFilteredAuditData] = useState([])

  const periodOptions = {
    HALF_YEARLY: [
      { value: "H1", label: "H1" },
      { value: "H2", label: "H2" },
      { value: "ONE_TIME", label: "ONE TIME" },
      { value: "EVERY_FY", label: "EVERY FINANCIAL YEAR" }
    ],
    QUARTERLY: [
      { value: "Q1", label: "Q1" },
      { value: "Q2", label: "Q2" },
      { value: "Q3", label: "Q3" },
      { value: "Q4", label: "Q4" },
      { value: "ONE_TIME", label: "ONE TIME" },
      { value: "EVERY_FY", label: "EVERY FINANCIAL YEAR" }
    ],
    MONTHLY: [
      { value: "ONE_TIME", label: "ONE TIME" },
      { value: "EVERY_FY", label: "EVERY FINANCIAL YEAR" },
      ...Array.from({ length: 12 }, (_, i) => ({
        value: `M${i + 1}`,
        label: `M${i + 1}`,
      })),
    ],
    YEARLY: [{ value: "Y1", label: "Y1" }, { value: "ONE_TIME", label: "ONE TIME" },
    { value: "EVERY_FY", label: "EVERY FINANCIAL YEAR" }],


  };

  const getFrequency = async (fid) => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFrequency`,
      {},
      { financialYearId: fid },
      "GET"
    );
    if (isSuccess) {
      setFrequency(data.data);
    }
  };
  

  const calculateDateRange = (type, period, startingMonth, year) => {
    const startMonth = ((startingMonth - 1 + (period - 1) * type) % 12) + 1;
    const startYear =
      year + Math.floor((startingMonth - 1 + (period - 1) * type) / 12);
    const endMonth = ((startMonth - 1 + type) % 12) + 1;
    const endYear = startYear + Math.floor((startMonth - 1 + type) / 12);

    const formatDate = (month, year) =>
      `${year}-${month < 10 ? `0${month}` : month}`;


    return {
      fromDate: formatDate(startMonth, startYear),
      toDate: formatDate(endMonth, endYear),
    };
  };



  const handlePeriodChange = (selectedOption) => {
    setSelectedPeriod(selectedOption);
    const year = parseInt(selectedYear.split("-")[0]);

    const filteredData = auditData.filter((audit) => {
      if (!selectedOption || !selectedOption.value) return false;

      const { value } = selectedOption;
      let dateRange = null;

      if (value.startsWith("H")) {
        const halfYear = value === "H1" ? 1 : 2;
        dateRange = calculateDateRange(6, halfYear, currentUser.starting_month, year);
      } else if (value.startsWith("Q")) {
        const quarter = parseInt(value.replace("Q", ""));
        dateRange = calculateDateRange(3, quarter, currentUser.starting_month, year);
      } else if (value.startsWith("M")) {
        const month = parseInt(value.replace("M", ""));
        dateRange = calculateDateRange(1, month, currentUser.starting_month, year);
      } else if (value === "ONE_TIME" || value === "EVERY_FY") {
        return audit.matchingAuditors[0].auditDetail.some((detail) => detail.frequency === value);
      }

      return dateRange
        ? audit.matchingAuditors[0].auditDetail.some((detail) => detail.fromDate === dateRange.fromDate)
        : false;
    });

    setFilteredAuditData(filteredData);
  };


  const handleYearChange = async (e) => {
    const selectedValue = e.target.value;
    const newTimePeriods = {};
    const year = parseInt(selectedValue.split("-")[0]);
    const selectedOption = financialYear.find(
      (year) => year.financial_year_value === selectedValue
    );
    setSelectedYear(selectedValue);
    getFrequency(selectedOption?.id);
    setFinancialYearId(selectedOption?.id || "");
    let status;
    switch (activeTab) {
      case "accepted":
        status = "ACCEPTED";
        break;
      case "rejected":
        status = "REJECTED";
        break;
      case "reassigned":
        status = "REASSIGNED";
        break;
      default:
        status = "ACCEPTED";
    }


    let data = await getAuditHistory(selectedOption?.id, status)





    // Filter the auditData based on the selected period option
    const filteredAudittData = data.filter((audit) => {
      let match = false;
      let dateRange; // Define dateRange here

      if (selectedPeriod?.value === "ONE_TIME") {
        // Filter for ONE_TIME
        match = audit.matchingAuditors[0].auditDetail.every(detail => detail.frequency === "ONE_TIME");
      } else if (selectedPeriod?.value === "EVERY_FY") {
        // Filter for EVERY_FY
        match = audit.matchingAuditors[0].auditDetail.every(detail => detail.frequency === "EVERY_FY");
      } else if (selectedPeriod?.value?.startsWith("H")) {
        // Handle HALF_YEARLY periods (H1, H2)
        const halfYear = selectedPeriod.value === "H1" ? 1 : 2;
        dateRange = calculateDateRange(6, halfYear, currentUser.starting_month, year);

        match = audit.matchingAuditors[0].auditDetail.every(detail => detail.fromDate === dateRange.fromDate); // or apply specific logic if needed
      } else if (selectedPeriod?.value?.startsWith("Q")) {
        // Handle QUARTERLY periods (Q1, Q2, Q3, Q4)
        const quarter = parseInt(selectedPeriod.value.replace("Q", ""));
        dateRange = calculateDateRange(3, quarter, currentUser.starting_month, year);
        match = audit.matchingAuditors[0].auditDetail.every(detail => detail.fromDate === dateRange.fromDate);
      } else if (selectedPeriod?.value?.startsWith("M")) {
        // Handle MONTHLY periods (M1, M2, etc.)
        const month = parseInt(selectedPeriod.value.replace("M", ""));
        dateRange = calculateDateRange(1, month, currentUser.starting_month, year);
        match = true; // or apply specific logic if needed
      } else if (selectedPeriod?.value === "Y1") {
        // Handle YEARLY period (Y1)
        dateRange = calculateDateRange(12, 1, currentUser.starting_month, year);
        match = true; // or apply specific logic if needed
      }

      // Store the calculated date range for non-filtering periods
      if (dateRange) {
        newTimePeriods[selectedPeriod?.value?.toLowerCase()] = dateRange?.fromDate;
      }

      return match;
    });

    setTimePeriods(newTimePeriods);
    setFilteredAuditData(filteredAudittData);

  };



  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setFinancialYear(data.data);
      const lastEntry = data.data[data.data.length - 1];
      setSelectedYear(lastEntry.financial_year_value);
      getFrequency(lastEntry.id);
      setFinancialYearId(lastEntry.id);
      return lastEntry.id;
    }
  };

  useEffect(() => {
    let a = getFinancialYear();
  }, [])



  const getAuditHistory = async (year, status) => {
    const financialYear = year
    const { isSuccess, error, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getAuditHistory`,
      {},
      {
        status: status,
        financialYearId: financialYear,
      },
      "GET"
    );

    if (isSuccess) {
      const tmpData = location.state?.reportingQuestion.length
        ? data?.data.filter(item => {
          const isQuestionIncluded = location.state?.reportingQuestion.includes(item?.question?.questionId);

          if (isQuestionIncluded) {
            item.matchingAuditors = item?.matchingAuditors.map(auditor => ({
              ...auditor,
              auditDetail: auditor.auditDetail.filter(r => Number(r.id) === Number(location.state?.userId))
            }));
            return true;
          }
          return false;
        })
        : data?.data;

      if (isMounted.current)
      {
        setAuditData(tmpData);
      }
        
      return tmpData
    }

    if (error) {
      console.error("Error fetching audit history", error);
    }
  };

  // useEffect(()=>{
  //   setFilteredAuditData(auditData)
  //   renderTableData()
  // },[auditData,selectedPeriod])

  useEffect(() => {
    let status;
    switch (activeTab) {
      case "accepted":
        status = "ACCEPTED";
        break;
      case "rejected":
        status = "REJECTED";
        break;
      case "reassigned":
        status = "REASSIGNED";
        break;
      default:
        status = "ACCEPTED";
    }
    if (financialYearId) {
      let data = getAuditHistory(financialYearId, status);


    }
  }, [activeTab, selectedPeriod, financialYearId, location.state?.reportingQuestion]);



  const renderTableData = () => {
    if (!filteredAuditData || filteredAuditData.length === 0) {
      return (
        <tr>
          <td>No data available</td>
        </tr>
      );
    }
    const handleViewClick = (item) => {
      console.log(item)
      setItem(item)

      setShow(true)
    };
    let rowIndex = 0;

    return filteredAuditData.flatMap(
      (item) =>
        item.matchingAuditors?.[0]?.auditDetail?.map((detail) => (

          <tr key={`${item.id}-${rowIndex}`}>
            <td className="audit-td">{++rowIndex}</td> {/* Increment rowIndex for each row */}
            <td className="audit-td">
              {detail?.auditedDate
                ? new Date(detail.auditedDate).toLocaleDateString()
                : 'No Date'}
            </td>
            <td className="audit-td">{detail?.first_name ?? 'N/A'}</td>
            {/* <td className="audit-td">{detail?.remark ?? 'N/A'}</td> */}
            <td className="audit-td">{item?.title ?? 'No Title'}</td>

            <td><Button style={{ fontSize: "12px" }} onClick={() => handleViewClick(item)}>Answer</Button></td>

          </tr>
        )) ?? []
    );
  };

  useEffect(() => {
    if (filteredAuditData) {
      renderTableData()

    }

  }, [filteredAuditData])

  const renderQuestionType = (item) => {
    switch (item?.question.questionType) {
      case 'qualitative':
        return <QualitativeAnswer item={item} answer={item?.matchingAuditors[0]?.auditDetail[0]?.answer.answer} />;
      case 'tabular_question':
        return (
          <TabularAnswer item={item} />
        );
      case 'quantitative':
        return <QualitativeQuestionType note={item?.note} title={item?.title} answer={item.matchingAuditors[0].auditDetail[0].answer} />;
      case 'yes_no':
        return <YesNoAnswer item={item} />;
      case 'quantitative_trends':
        return <QuantitativeTrendsType item={item} note={item?.note} title={item.title} answer={item.matchingAuditors[0].auditDetail[0].answer} />;
      default:
        return <p>Unknown question type</p>;
    }
  };

  return (
    <div>
      <div className="tabs-audit" style={{ width: "100%" }}>
        <div style={{ width: "15%" }}>
          <button
            className={activeTab === "accepted" ? "active" : ""}
            onClick={() => setActiveTab("accepted")}
            style={{ width: "100%" }}
          >
            Accepted
          </button>
        </div>
        <div style={{ width: "15%" }}>
          <button
            style={{ width: "100%" }}
            className={activeTab === "rejected" ? "active" : ""}
            onClick={() => setActiveTab("rejected")}
          >
            Rejected
          </button>
        </div>

        <div style={{ width: "15%" }}>
          <button
            style={{ width: "100%" }}
            className={activeTab === "reassigned" ? "active" : ""}
            onClick={() => setActiveTab("reassigned")}
          >
            Reassigned
          </button>
        </div>

        <div style={{ width: "25%" }}>
          <Form.Group as={Row} style={{ width: "100%" }} controlId="financialYearSelect" className="ml-2">
            <Col sm="auto">
              <Form.Select
                className=""
                onChange={handleYearChange}
                value={selectedYear}
                style={{ padding: ".375rem 2.25rem .375rem .75rem" }}
              >
                {financialYear.map((year) => (
                  <option key={year.id} value={year.financial_year_value}>
                    FY - {"           "}
                    {year.financial_year_value}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>
        </div>
        <div style={{ width: "30%" }}>
          <div className="color_rent mb-0" style={{ width: "100%", padding: "0px" }}>
            {frequency === "HALF_YEARLY" && (
              <Select

                value={selectedPeriod}
                onChange={handlePeriodChange}
                options={periodOptions[frequency] || []}
                placeholder="Select Periods"
                className=""
                styles={{
                  control: (base) => ({
                    ...base,
                    width: "100%",
                    paddingLeft: "5px",
                    paddingRight: "00px",
                  }),
                }}
              />
            )}
            {frequency === "QUARTERLY" && (
              <Select

                value={selectedPeriod}
                onChange={handlePeriodChange}
                options={periodOptions[frequency] || []}
                placeholder="Select Periods"
                className="esg_text2"
                styles={{
                  control: (base) => ({
                    ...base,
                    paddingLeft: "5px",
                    paddingRight: "0px",
                  }),
                }}
              />
            )}
            {frequency === "MONTHLY" && (
              <Select

                value={selectedPeriod}
                onChange={handlePeriodChange}
                options={periodOptions[frequency] || []}
                placeholder="Select Periods"
                className="esg_text2"
                styles={{
                  control: (base) => ({
                    ...base,
                    paddingLeft: "5px",
                    paddingRight: "0px",
                  }),
                }}
              />
            )}
            {frequency === "YEARLY" && (
              <Select

                value={selectedPeriod}
                onChange={handlePeriodChange}
                options={periodOptions[frequency] || []}
                placeholder="Periods"
                className="esg_text2"
                styles={{
                  control: (base) => ({
                    ...base,
                    paddingLeft: "5px",
                    paddingRight: "0px",
                  }),
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className="table-container"
        style={{ borderColor: "white", maxHeight: "80vh", height: "80vh" }}
      >
        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Audited Date</th>
              <th>Audited By</th>
              {/* <th>Audited Remark</th> */}
              <th>{`Questions ${activeTab.charAt(0).toUpperCase() + activeTab.slice(1)
                }`}</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </table>
        <Modal size="lg" show={show} onHide={handleClose} style={{ height: "115vh" }}>
          <Modal.Header closeButton>

          </Modal.Header>
          <Modal.Body>
            {/* You can display the details of the item here */}
            {item ? renderQuestionType(item) : <p>No details available.</p>}
          </Modal.Body>

        </Modal>

      </div>
    </div>
  );
};

export default AuditTabs;
