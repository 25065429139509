import React, { useState, useEffect } from "react";
import config from "../../../../config/config.json";
import { apiCall } from "../../../../_services/apiCall";
import Excel from "./excel";

export default function ParentExcel({ allAnswers }) {
  const sourceIds = Array.from(new Set(allAnswers.map(item => item.sourceId)));
  const [sourceLabels, setUserLabels] = useState({});
  const [selectedSourceId, setSelectedSourceId] = useState(null); // Default to "All Users"
  const [aggregatedData, setAggregatedData] = useState([]);

  const getLocations = async () => {
    console.log("Get Locations invoked");
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
        {},
        {},
        "GET"
      );

      if (isSuccess && data?.data) {
        console.log("getSource returned: ", data);

        const labels = data.data.reduce((acc, item) => {
          if (item.id) {
            acc[item.id] = item.unitCode;
          }
          return acc;
        }, {});

        console.log("Mapped User Areas:", labels);
        setUserLabels(labels);
      } else {
        console.warn("getSource API call unsuccessful or returned no data");
      }
    } catch (error) {
      console.error("Error fetching source labels:", error);
    }
  };
  const computeAggregatedData = () => {
    console.log("Computing aggregated data...");

    const aggregated = allAnswers.reduce((acc, item) => {
      // Skip items without a valid 'answer'
      if (!item.answer || item.answer.trim() === "") {
        return acc; // Always return the accumulator
      }

      try {
        let parsedAnswer = JSON.parse(item.answer);

        // Handle different formats of 'parsedAnswer'
        if (Array.isArray(parsedAnswer)) {
          // Example: [["YES","100"]]
          // Adjust based on your actual data structure
          parsedAnswer = {
            questionId: parsedAnswer[0][0], // "YES"
            readingValue: Number(parsedAnswer[0][1]) || 0, // 100
            questionTitle: "Untitled Question", // Assign appropriately
            unit: "",
          };
        }

        // Ensure parsedAnswer is an object
        if (typeof parsedAnswer !== 'object' || parsedAnswer === null) {
          console.warn("Parsed answer is not an object:", parsedAnswer);
          return acc;
        }

        const { questionId, readingValue = 0, questionTitle, unit } = parsedAnswer;

        // Validate required fields
        if (!questionId) {
          console.warn("Missing questionId in parsedAnswer:", parsedAnswer);
          return acc;
        }

        // Find existing entry for the questionId
        const existing = acc.find(a => a.questionId === questionId);
        if (existing) {
          // Check if both existing and new readingValues are numbers
          if (typeof existing.readingValue === "number" && typeof readingValue === "number") {
            existing.readingValue += readingValue; // Sum the reading values
          } else {
            // If not both numbers, concatenate as strings with a comma separator
            existing.readingValue = String(existing.readingValue) + `, ${readingValue}`;
          }
        } else {
          // Add a new entry to the accumulator
          acc.push({
            questionId,
            readingValue,
            questionTitle: questionTitle || "Untitled Question",
            unit: unit || "",
          });
        }
      } catch (error) {
        console.error("Error parsing answer:", item.answer, error);
        // Optionally, you can choose to handle specific formats here
      }

      // Always return the accumulator for the next iteration
      return acc;
    }, []);

    console.log("Aggregated data:", aggregated);
    setAggregatedData(aggregated);
  };


  useEffect(() => {
    getLocations();
    computeAggregatedData();
  }, [allAnswers]);

  const filteredData = selectedSourceId
    ? selectedSourceId === "ALL"
      ? aggregatedData
      : allAnswers.filter(item => item.sourceId === selectedSourceId)
    : [];

  return (
    <div>
      <label style={{ marginRight: "8px" }}>Select User:</label>
      <select
        value={selectedSourceId !== null ? selectedSourceId : ""}
        onChange={(e) =>
          setSelectedSourceId(
            e.target.value ? (e.target.value === "ALL" ? "ALL" : parseInt(e.target.value)) : null
          )
        }
      >
        {/* <option value="ALL">ALL</option> */}
        {sourceIds.map((id) => (
          <option key={id} value={id}>
            {sourceLabels[id] || `User ${id}`}
          </option>
        ))}
      </select>

      <div style={{ marginTop: "16px" }}>
        <Excel data={filteredData} />
      </div>
    </div>
  );
}
