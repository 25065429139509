import React, { useState, useEffect } from "react";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import Chart from "react-apexcharts"; // Assuming you are using ApexCharts


const FourtyEightTotalEnergy = ({
  timePeriods,
  locationOption,
  brief,
  timePeriodValues,
}) => {
  const [chartSeries, setChartSeries] = useState([]);
  const formatValue = (value) => {
    if (value >= 1e6) {
      return `${(value / 1e6).toFixed(0)}M`; // Format millions
    } else if (value >= 1e3) {
      return `${(value / 1e3).toFixed(0)}K`; // Format thousands
    } else {
      return Math.round(value); // Format normal numbers
    }
  };

  const formatNumberWithIndianCommas = (number) => {
    const x = number.toString().split('.');
    let num = x[0];
    let lastThree = num.slice(-3);
    const rest = num.slice(0, -3);

    if (rest !== '') {
      lastThree = ',' + lastThree;
      const result = rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
      num = result + lastThree;
    } else {
      num = lastThree;
    }

    return x.length > 1 ? num + '.' + x[1] : num; // Just format the number without manipulating the input string
  };

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        enabled: false, show: false
      }
    },
    plotOptions: {
      bar: {
        // borderRadius: 5,
        horizontal: false,

        columnWidth: "25%",
        // endingShape: "rounded",  
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return `${formatNumberWithIndianCommas(val)}`; // Display formatted values inside bars
      },
      offsetY: 0, // Center the label vertically inside the bar
      style: {
        fontSize: "10px",
        colors: ["#fff"], // White text inside bars
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["#83bbd5"],
    },
    xaxis: {
      categories: [], // This will be set dynamically based on locationOption
      title: {
        text: timePeriodValues.length > 1 ? "---------- Time Period ----------" : locationOption.length > 1 ? " --------- Locations ---------" : "",
        style: {
          fontSize: '12px',    // Customize the font size
          fontWeight: 400,  // Customize the font weight, e.g., 'bold', 'normal'
          fontFamily: 'Arial', // Customize the font family
          color: '#011627',       // Customize the font color

        },
      },
      labels: {
        style: {
          colors: '#7b91b0',  // Light blue color for the y-axis labels
          fontSize: '12px',   // Adjust the label font size if needed
          fontFamily: 'Poppins',
        },
      },
    },
    yaxis: {
      categories: [], // This will be set dynamically based on locationOption
      title: {
        text: "---------- Energy in GJ -----------",
        style: {
          fontSize: '12px',    // Customize the font size
          fontWeight: 400,  // Customize the font weight, e.g., 'bold', 'normal'
          fontFamily: 'Arial', // Customize the font family
          color: '#011627',       // Customize the font color

        },
      },

      min: 0,
      labels: {
        style: {
          colors: ['#7b91b0'],
          fontSize: '12px',
          fontFamily: 'Poppins',
        },
        formatter: (value) => formatValue(value), // Format y-axis labels
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${formatNumberWithIndianCommas(val)} GJ`;
        },
      },
    },
    colors: ["#83bbd5"],
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.5,
      },
    },
  });

  useEffect(() => {
    if (locationOption.length > 1 && timePeriodValues.length === 1) {
      const categories = Object.keys(brief.time);

      // Calculate total energy for each location (M1, M2, etc.)
      const series = categories.map((locationKey) => {
        const locationData = brief.time[locationKey]; // Get M1, M2, etc.

        // Calculate the total energy by summing the first elements of each key's array
        const totalEnergy = Object.values(locationData).reduce((sum, valueArray) => {
          const numValue = Array.isArray(valueArray) ? Number(valueArray[0]) : 0; // First element of the array
          return sum + (isNaN(numValue) ? 0 : numValue);
        }, 0);

        return totalEnergy; // Total energy for the current location (M1, M2, etc.)
      });

      // Update the chart series and options
      // const maxSeriesValue = Math.max(...series);

      // Update the chart series and options
      setChartSeries([{ name: "Total Energy", data: series }]);
      setChartOptions((prev) => ({
        ...prev,
        xaxis: {
          ...prev.xaxis,
          categories: categories, // M1, M2, etc.
        },
        yaxis: {
          ...prev.yaxis,
          // max: maxSeriesValue + 10000, // Set max value of y-axis based on the total sum + 10000
        }
      }));
    } else if (locationOption.length === 1 && timePeriodValues.length > 1) {
      // Extract keys from timePeriods object for x-axis categories
      const categories = Object.keys(brief.location);

      // Calculate total energy for each location (M1, M2, etc.)
      const series = categories.map((locationKey) => {
        const locationData = brief.location[locationKey]; // Get M1, M2, etc.

        // Calculate the total energy by summing the first elements of each key's array
        const totalEnergy = Object.values(locationData).reduce((sum, valueArray) => {
          const numValue = Array.isArray(valueArray) ? Number(valueArray[0]) : 0; // First element of the array
          return sum + (isNaN(numValue) ? 0 : numValue);
        }, 0);

        return totalEnergy; // Total energy for the current location (M1, M2, etc.)
      });

      // Update the chart series and options
      // const maxSeriesValue = Math.max(...series);

      // Update the chart series and options
      setChartSeries([{ name: "Total Energy", data: series }]);
      setChartOptions((prev) => ({
        ...prev,
        xaxis: {
          ...prev.xaxis,
          categories: categories, // M1, M2, etc.
        },
        yaxis: {
          ...prev.yaxis,
          // max: maxSeriesValue + 10000, // Set max value of y-axis based on the total sum + 10000
        }
      }));


    } else {
      // Extract keys from timePeriods object for x-axis categories
      const categories = Object.keys(brief.location);

      // Calculate total energy for each location (M1, M2, etc.)
      const series = categories.map((locationKey) => {
        const locationData = brief.location[locationKey]; // Get M1, M2, etc.

        // Calculate the total energy by summing the first elements of each key's array
        const totalEnergy = Object.values(locationData).reduce((sum, valueArray) => {
          if (Array.isArray(valueArray)) {
            // Sum all elements in the array
            const arraySum = valueArray.reduce((innerSum, num) => {
              const numValue = Number(num); // Convert each element to a number
              return innerSum + (isNaN(numValue) ? 0 : numValue); // Add to the sum if it's a valid number
            }, 0);

            return sum + arraySum; // Add the sum of the array to the total sum
          }
          return sum;
        }, 0);

        return totalEnergy; // Total energy for the current location (M1, M2, etc.)
      });

      // Update the chart series and options
      // const maxSeriesValue = Math.max(...series);

      // Update the chart series and options
      setChartSeries([{ name: "Total Energy", data: series }]);
      setChartOptions((prev) => ({
        ...prev,
        xaxis: {
          ...prev.xaxis,
          categories: categories, // M1, M2, etc.
        },
        yaxis: {
          ...prev.yaxis,
          // max: maxSeriesValue + 10000, // Set max value of y-axis based on the total sum + 10000
        }
      }));
    }
  }, [locationOption, timePeriodValues,]);

  return (
    <div className="container" style={{ height: "100%" }}>
      <div style={{ height: "10%", fontSize: "20px", fontWeight: 600, fontColor: "#011627" }}>Total Energy Consumption</div>
      <div style={{ height: "90%" }}>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={"100%"}
        />
      </div>
    </div>
  );
};

export default FourtyEightTotalEnergy;
