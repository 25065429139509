import React, { useEffect } from "react";
import { Form, Row, Col, Dropdown, Modal, Button } from "react-bootstrap";
import { IoDownloadOutline } from "react-icons/io5";
import { DetailModal } from "./DetailModal";
import { useState } from "react";
import { FaEye, FaUpload } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { apiCall } from "../../../../_services/apiCall";
import swal from "sweetalert";
import config from "../../../../config/config.json";
import { FaEdit } from "react-icons/fa";
const TrendsComponent = ({
  sourceData,
  menu,
  currentUserId,
  assignedToDetails,
  auditItem,
  financeObject,
  getAuditListing,
  apiData,
  item,
  answer,
  setAnswer,
  toDate,
  fromDate,
  sourceChange,
  singleItem,
  unit,
  selectedSubLocationId,
  subMenuId
}) => {
  const [answerIdReal, setAnswerIdReal] = useState();
  const [status, setStatus] = useState();
  const [note, setNote] = useState("");
  const [readingValue, setReadingValue] = useState("");
  const [preMonthReadingValue, setPreMonthReadingValue] = useState("");
  const [meterList, setMeterList] = useState([]);
  const [processList, setProcessList] = useState([]);
  const [letestAnswer, setLetestAnswer] = useState([]);
  const [targetAnswer, setTargetAnswer] = useState([]);
  const [remark, setRemark] = useState("");
  const [answerr, setAnswerr] = useState({});
  const [check, setCheck] = useState(false);
  const [edit, setEdit] = useState(true);
  const [auditButton, setAuditButton] = useState(false);
  const [comment, setComment] = useState([[]]);
  const [target, setTarget] = useState();
  const [targetValue, setTargetValue] = useState();
  const [proofDocument, setProofDocument] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [showData, setShowData] = useState("");
  const [commentModal, setCommentModal] = useState(false);
  const [comments, setComments] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState();
  const [confirmTarget, setConfirmTarget] = useState(false);
  const [showEmissionModal, setShowEmissionModal] = useState(false);
  const [emissionData, setEmissionData] = useState(null);
  const handleShow = () => setShowEmissionModal(true);
  const itemHeight = "6vh"; // Adjust height as per design
  const inputStyle = {
    height: itemHeight,
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #ccc",

    backgroundColor: "white",
    borderRadius: "5px",
  };

  const handleDoubleClick = (data) => {
    if (data.trim() !== "") {
      // Check if data is not an empty string
      setShowData(data);
      setIsModalOpen(true);
    }
  };
  const getPreviousMonthRange = async (fromDate, toDate) => {
    if (fromDate && toDate) {
      const parseDate = (dateStr) => {
        const [year, month] = dateStr.split("-").map(Number);
        return new Date(year, month - 1); // Month is 0-indexed in JavaScript
      };

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        return `${year}-${month}`;
      };

      const from = parseDate(fromDate);
      const to = parseDate(toDate);

      // Subtract one month
      from.setMonth(from.getMonth() - 1);
      to.setMonth(to.getMonth() - 1);

      return {
        from: formatDate(from),
        to: formatDate(to),
      };
    }
  };
  const initializeAnswer = async () => {
    if (item && item.questionId && letestAnswer && letestAnswer.length) {
      // const matchedAnswer = savedAnswers.find(
      //   (savedAnswer) => savedAnswer.questionId == item.questionId
      // );
      let matchedAnswer;
      let preMonthMatchedAnswer;
      if (item?.frequency == "CUSTOM") {
        matchedAnswer = selectedSubLocationId ?letestAnswer.filter(
          (savedAnswer) =>
            savedAnswer.questionId == item.questionId &&
            savedAnswer.toDate == toDate &&
            savedAnswer.fromDate == fromDate &&
            savedAnswer.sourceId == sourceChange &&
            savedAnswer.subLocationId == selectedSubLocationId
        ) : letestAnswer.filter(
          (savedAnswer) =>
            savedAnswer.questionId == item.questionId &&
            savedAnswer.toDate == toDate &&
            savedAnswer.fromDate == fromDate &&
            savedAnswer.sourceId == sourceChange
        );
        // if(sourceChange && !subMenuId){
        //   console.log(subMenuId,matchedAnswer,fromDate,toDate,sourceChange)

        //   let matchedAnswers =  letestAnswer.filter(
        //     (savedAnswer) =>
        //       savedAnswer.questionId == item.questionId &&
        //       savedAnswer.toDate == toDate &&
        //       savedAnswer.fromDate == fromDate &&
        //       savedAnswer.sourceId == sourceChange
        //   );
          if (matchedAnswer.length ) {
          console.log(subMenuId,matchedAnswer,fromDate,toDate,sourceChange)

          // Clone the first object as a base
          const mergedObject = { ...matchedAnswer[0] };
        
          // Initialize readingValue sum
          let totalReadingValue = 0;
        
          matchedAnswer.forEach((item) => {
            if (item.answer) {
              const answerObj = JSON.parse(item.answer);
              totalReadingValue += Number(answerObj.readingValue || 0); // Convert to number and sum
            }
          });
        
          // Update the answer field with the new summed readingValue
          const updatedAnswer = JSON.parse(mergedObject.answer);
          updatedAnswer.readingValue = totalReadingValue;
          mergedObject.answer = JSON.stringify(updatedAnswer);
          matchedAnswer = mergedObject;
          console.log(subMenuId,matchedAnswer,fromDate,toDate,sourceChange)

        }
        if(subMenuId){
          console.log(subMenuId,matchedAnswer,fromDate,toDate,sourceChange)
          matchedAnswer = letestAnswer.find(
            (savedAnswer) =>
              savedAnswer.questionId == item.questionId &&
              savedAnswer.toDate == toDate &&
              savedAnswer.fromDate == fromDate &&
              savedAnswer.sourceId == sourceChange &&
              savedAnswer.subLocationId == subMenuId
          )
          console.log(subMenuId,matchedAnswer,fromDate,toDate,sourceChange)
        }
        setAnswerIdReal(matchedAnswer?.id);
        const preMonth = await getPreviousMonthRange(toDate, fromDate);
        if (preMonth) {
          preMonthMatchedAnswer = selectedSubLocationId ?letestAnswer.find(
            (savedAnswer) =>
              savedAnswer.questionId == item.questionId &&
            savedAnswer.toDate == preMonth.from &&
            savedAnswer.fromDate == preMonth.to &&
            savedAnswer.sourceId == sourceChange &&
              savedAnswer.subLocationId == selectedSubLocationId
          ) : letestAnswer.find(
            (savedAnswer) =>
              savedAnswer.questionId == item.questionId &&
              savedAnswer.toDate == preMonth.from &&
              savedAnswer.fromDate == preMonth.to &&
              savedAnswer.sourceId == sourceChange
          );
        }
      } else {
        matchedAnswer = selectedSubLocationId ? letestAnswer.find(
          (savedAnswer) => savedAnswer.questionId == item.questionId && savedAnswer.subLocationId == selectedSubLocationId
        ):letestAnswer.find(
          (savedAnswer) => savedAnswer.questionId == item.questionId
        );
        setAnswerIdReal(matchedAnswer?.id);
      }
      if (matchedAnswer) {
        setCheck(true);
        setAnswerIdReal(matchedAnswer?.id);
        setStatus(matchedAnswer?.status);
        try {
          const answerObject = JSON.parse(matchedAnswer.answer);
          if (preMonthMatchedAnswer) {
            const preMonthAnswerObject =
              preMonthMatchedAnswer &&
              JSON.parse(preMonthMatchedAnswer?.answer);
            setPreMonthReadingValue(preMonthAnswerObject?.readingValue);
          }
          // Extract the process ID
          const processId = answerObject.process || "";

          // Find the process name from processList
          const matchedProcess = processList.find(
            (process) => process.id === parseInt(processId)
          );

          const processName = matchedProcess ? matchedProcess.process : "";         

          if (Array.isArray(matchedAnswer.proofDocumentNote)) {
            // Check if the first element is an array (indicating a 2D array)
            if (Array.isArray(matchedAnswer.proofDocumentNote[0])) {
              setComment(matchedAnswer.proofDocumentNote);
            } else {
              // It's a 1D array, so wrap it in another array to make it 2D
              setComment([matchedAnswer.proofDocumentNote]);
            }
          } else {
            // If matchedAnswer.proofDocument is not an array, set it to an empty 2D array
            setComment([[]]);
          }

          // Set the state variables

          // setSelectedUnit(answerObject.unit || "");
          setReadingValue(answerObject?.readingValue);
          setNote((prevState) => {
            // Check if matchedAnswer.note is a string
            if (typeof matchedAnswer.note === "string") {
              return [[matchedAnswer.note]]; // Wrap the string in a 2D array
            }

            // Check if matchedAnswer.note is a 2D array
            if (
              Array.isArray(matchedAnswer.note) &&
              Array.isArray(matchedAnswer.note[0])
            ) {
              return matchedAnswer.note; // Use it as is
            }

            // If matchedAnswer.note is not a string or a 2D array, return an empty 2D array
            return [[""]];
          });
          if (Array.isArray(matchedAnswer.proofDocument)) {
            // Check if the first element is an array (indicating a 2D array)
            if (Array.isArray(matchedAnswer.proofDocument[0])) {
              setProofDocument(matchedAnswer.proofDocument);
            } else {
              // It's a 1D array, so wrap it in another array to make it 2D
              setProofDocument([matchedAnswer.proofDocument]);
            }
          } else {
            // If matchedAnswer.proofDocument is not an array, set it to an empty 2D array
            setProofDocument([[]]);
          }
          setAnswer((prevState) => ({
            ...prevState,
            questionId: item?.questionId,
            moduleId: item?.moduleId,
            questionType: item?.questionType,
            frequency: item?.frequency,
            questionTitle: item?.title,

            fromDate: fromDate,
            toDate: toDate,
            response: matchedAnswer.answer || "",
            comment: matchedAnswer?.comment || [[]],
            note: (() => {
              // Check if matchedAnswer.note is a string
              if (typeof matchedAnswer.note === "string") {
                return [[matchedAnswer.note]]; // Wrap the string in a 2D array
              }

              // Check if matchedAnswer.note is a 2D array
              if (
                Array.isArray(matchedAnswer.note) &&
                Array.isArray(matchedAnswer.note[0])
              ) {
                return matchedAnswer.note; // Use it as is
              }

              // If matchedAnswer.note is not a string or a 2D array, return an empty 2D array
              return [[""]];
            })(),
            proofDocument: (() => {
              if (Array.isArray(matchedAnswer.proofDocument)) {
                // Check if the first element is an array (indicating a 2D array)
                if (Array.isArray(matchedAnswer.proofDocument[0])) {
                  return matchedAnswer.proofDocument; // Use it as is
                } else {
                  // It's a 1D array, so wrap it in another array to make it 2D
                  return [matchedAnswer.proofDocument];
                }
              } else {
                // If matchedAnswer.proofDocument is not an array, set it to an empty 2D array
                return [[]];
              }
            })(),
          }));
        } catch (error) {
          console.error("Error parsing matchedAnswer.answer:", error);
        }
      } else {
        setCheck(false);
        setAnswer((prevState) => ({
          ...prevState,
          questionId: item?.questionId,
          fromDate: fromDate,
          toDate: toDate,
          moduleId: item?.moduleId,
          questionTitle: item?.title,

          questionType: item?.questionType,
          frequency: item?.frequency,
          note: [[""]],
        }));

        setReadingValue("");
        setNote("");
        setProofDocument([[]]);
      }
    } else {
      setCheck(false);
    }
  };

  const handleDocCommentChange = (e, index) => {
    const newCommentText = e.target.value;

    // 1) Update local 'comment' state
    setComment((prev) => {
      // Make a copy of the 2D array
      const updatedComments = prev.map((row) => [...row]);
      // If the first row doesn't exist, create it
      if (!Array.isArray(updatedComments[0])) {
        updatedComments[0] = [];
      }
      // Update the comment at this index
      updatedComments[0][index] = newCommentText;
      return updatedComments;
    });

    // 2) Update your 'answer' object
    setAnswer((prev) => {
      const updatedComments = Array.isArray(prev.comment)
        ? prev.comment.map((row) => [...row])
        : [[]];

      if (!Array.isArray(updatedComments[0])) {
        updatedComments[0] = [];
      }
      updatedComments[0][index] = newCommentText;

      return {
        ...prev,
        // If you want the final comments stored in 'comment':
        comment: updatedComments,
        // or if you store it in 'proofDocumentNote':
        // proofDocumentNote: updatedComments,
      };
    });
  };

  const initializeTarget = () => {
    if (item && item.questionId && apiData && apiData.length) {
      // const matchedAnswer = savedAnswers.find(
      //   (savedAnswer) => savedAnswer.questionId == item.questionId
      // );
      let matchedAnswer;
      if (item?.frequency == "CUSTOM") {
        matchedAnswer = apiData.find(
          (savedAnswer) =>
            savedAnswer.questionId == item.questionId &&
            savedAnswer.toDate == toDate &&
            savedAnswer.fromDate == fromDate &&
            savedAnswer.sourceId == sourceChange
        );
        setTarget(matchedAnswer?.targetData);
      } else {
        matchedAnswer = apiData.find(
          (savedAnswer) => savedAnswer.questionId == item.questionId
        );
        setTarget(matchedAnswer?.targetData);
      }
      if (matchedAnswer) {
      }
    }
  };

  const cancelComment = () => {
    setCommentModal(false);
    setFileToDelete(null);
  };

  const cancelTarget = () => {
    setConfirmTarget(false);
  };

  const filteredDocs = (proofDocument[0] || []).filter(
    (doc) => doc && typeof doc === "string" && doc.trim() !== ""
  );

  const filteredComments = Array.isArray(comment[0])
    ? comment[0].filter(
        (c, index) =>
          index < filteredDocs.length && (typeof c === "string" || c === "")
      )
    : [];

  useEffect(() => {
    initializeAnswer();
  }, [subMenuId,selectedSubLocationId,letestAnswer, toDate, fromDate, item, sourceChange]);

  useEffect(() => {
    if (singleItem && financeObject) getReportingAnswer();
    getTargerAnswer();
  }, [singleItem?.questionId]);

  useEffect(() => {
    if (sourceData) {
      const locationArray = sourceData?.reverse().map((item) => ({
        id: item.id,
        location: `${item?.location?.area}, ${item?.location?.city}, ${item?.location?.state}, ${item?.location?.country}, ${item?.location?.zipCode}`,
      }));
      if (locationArray && locationArray.length) {
        setMeterList(locationArray);
      }
      initializeAnswer();
      // initializeTarget();
    }
  }, [sourceData]);

  const handleEditClick = () => {
    setEdit(!edit);
  };

  const uploadFile = async (files) => {
    const selectedFile = files.target.files[0];
    const timestamp = new Date().getTime(); // Generate a timestamp
    const fileName = `${timestamp}_${selectedFile.name}`;

    const formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("fileName", fileName);
    formdata.append("filePath", "yasil/");

    const requestOptions = {
      header: {
        "Content-Type": "multipart/form-data", // Set the Content-Type header
      },
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${
          config.AUTH_API_URL_COMPANY
        }uploadFile?current_role=${localStorage.getItem("role")}`,
        requestOptions
      );
      const result = await response.text();
      const url = JSON.parse(result);

      // Append the new file URL to the list of proofDocument
      setProofDocument((prevState) => {
        const newProofDocument = [...prevState];

        if (!newProofDocument[0]) {
          newProofDocument[0] = [];
        }

        newProofDocument[0] = [...newProofDocument[0], url.url];

        return newProofDocument;
      });
      setAnswer((prevState) => {
        const newProofDocument = [...prevState.proofDocument];

        // If currentPage doesn't exist yet, initialize it as an empty array
        if (!newProofDocument[0]) {
          newProofDocument[0] = [];
        }

        // Update the specific page
        newProofDocument[0] = [...newProofDocument[0], url.url];

        return {
          ...prevState,
          questionId: item?.questionId,
          moduleId: item?.moduleId,
          questionType: item?.questionType,
          questionTitle: item?.title,
          frequency: item?.frequency,
          proofDocument: newProofDocument,
        };
      });
      setFile(`${timestamp}_${selectedFile.name}`);
      setCommentModal(true);
    } catch (error) {
      if (error) {
        swal({
          icon: "error",
          title: error,
          timer: 1000,
        });
      }
    }
  };
  const confirmDelete = () => {
    setProofDocument((prevState) => {
      // Clone the current proofDocument array
      const newProofDocument = [...prevState];

      // Update the specific page by filtering out the fileToDelete
      newProofDocument[0] = newProofDocument[0].filter(
        (url) => url !== fileToDelete
      );

      return newProofDocument;
    });
    setShowModal(false);
    setFileToDelete(null); // Clear the file to delete
  };

  // Cancel deletion
  const cancelDelete = () => {
    setShowModal(false);
    setFileToDelete(null);
  };

  const handleDeleteClick = (url) => {
    setFileToDelete(url);
    setShowModal(true);
  };

  const handleFileDownload = (url) => {
    const replacedUrl = url.replace(
      "https://riu-bucket.s3.ap-south-1.amazonaws.com",
      "https://copyadatafromawstoazure.blob.core.windows.net/uploads"
    );
    const link = document.createElement("a");
    link.href = replacedUrl;
    link.target = "_blank"; // Opens in a new tab
    link.download = replacedUrl.split("/").pop();
    document.body.appendChild(link); // Append to body
    link.click();
    document.body.removeChild(link); // Clean up
  };

  const getReportingAnswer = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getReportingAnswer`,
      {},
      { financialYearId: financeObject, questionId: singleItem.questionId },
      "GET"
    );
    if (isSuccess) {
      setLetestAnswer(data?.answers);
    }
  };

  const getTargerAnswer = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTargerAnswer`,
      {},
      { financialYearId: financeObject, questionId: singleItem.questionId },
      "GET"
    );
    if (isSuccess) {
      setTargetAnswer(data?.answers);
    }
  };

  const getEmissionCalculation = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getEmissionCalculation`,
      {},
      {
        questionId: singleItem.questionId,
        unit: unit?.unit || item?.details[0]?.option,
        value: readingValue,
      },
      "GET"
    );
    if (isSuccess) {
      handleShow();
      setEmissionData(data?.data);
    }
  };
  useEffect(() => {
    getEmissionCalculation();
  }, [readingValue]);

  useEffect(() => {
    if (item && item.questionId && targetAnswer && targetAnswer.length) {
      if (item?.frequency == "CUSTOM") {
        const matchedAnswer = targetAnswer.find(
          (savedAnswer) =>
            savedAnswer.questionId == item.questionId &&
            savedAnswer.toDate == toDate &&
            savedAnswer.fromDate == fromDate &&
            savedAnswer.sourceId == sourceChange
        );
        if (matchedAnswer && matchedAnswer.targetData) {
          setTargetValue(matchedAnswer.targetData);
        }
      }
    }
  }, [item.questionId, targetAnswer,sourceChange,toDate,fromDate]);

  // const handleReadingValue = (e) => {
  //   setReadingValue(Number(e.target.value));
  //   const value = e.target.value;
  //   setAnswerr((prevAnswerr) => ({
  //     ...prevAnswerr,
  //     questionId: item?.questionId,
  //     moduleId: item?.moduleId,
  //     questionType: item?.questionType,
  //     questionTitle: item?.title,
  //     fromDate:fromDate,
  //     toDate:toDate,
  //     frequency: item?.frequency,
  //     readingValue: Number(value),
  //   }));
  //   setAnswer((prevAnswer) => ({
  //     ...prevAnswer,
  //     readingValue: Number(value),
  //     questionTitle: item?.title,
  //   }));
  // };

  const handleReadingValue = (e) => {
    const value = e.target.value; // Keep it as a string initially
    setReadingValue(value); // Update readingValue as string

    // // Convert to Number only when updating the dependent state
    // const numericValue = parseFloat(value) || 0; // Safely parse, fallback to 0 for invalid numbers
    setAnswerr((prevAnswerr) => ({
      ...prevAnswerr,
      questionId: item?.questionId,
      moduleId: item?.moduleId,
      questionType: item?.questionType,
      questionTitle: item?.title,
      fromDate: fromDate,
      toDate: toDate,
      frequency: item?.frequency,
      readingValue: value,
    }));
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      readingValue: parseFloat(value) || 0,
      questionTitle: item?.title,
    }));
  };

  // const handlePeriodSelect = (key) => {
  //   setSelectedPeriodd(key);
  //   setAnswerr((prevAnswerr) => ({
  //     ...prevAnswerr,
  //     process: key,
  //   }));
  // };
  // const handlePeriodSelect = (key) => {
  //   // Find the selected process by ID
  //   const selectedProcess = processList.find(
  //     (item) => item.id === parseInt(key)
  //   );

  //   if (selectedProcess) {
  //     // Set the process name and ID
  //     setPeriodName(selectedProcess.process); // Set the process name
  //     setSelectedPeriodd(selectedProcess.id); // Set the process ID

  //     // Update the answer object with the process ID
  //     setAnswerr((prevAnswerr) => ({
  //       ...prevAnswerr,
  //       questionId: item?.questionId,
  //       moduleId: item?.moduleId,
  //       questionType: item?.questionType,
  //       frequency: item?.frequency,
  //       process: selectedProcess.id, // Store the process ID
  //     }));
  //   }
  // };
  const handleRemarkChange = (e) => {
    const newRemark = e.target.value;
    setRemark(newRemark);
  };

  const handleComment = (e) => {
    setComments(e.target.value);
  };

  const handleCommentChange = (value) => {
    setComment((prevState) => {
      const newProofDocument = [...prevState];

      if (!newProofDocument[0]) {
        newProofDocument[0] = [];
      }

      newProofDocument[0] = [...newProofDocument[0], value];

      return newProofDocument;
    });

    setAnswer((prevState) => {
      // Clone the current proofDocument
      const newProofDocument = Array.isArray(prevState.comment)
        ? prevState.comment
        : [[]]; // or however you'd like to fallback

      // If currentPage doesn't exist yet, initialize it as an empty array
      if (!newProofDocument[0]) {
        newProofDocument[0] = [];
      }

      // Update the specific page
      newProofDocument[0] = [...newProofDocument[0], value];

      // Return the updated state
      return {
        ...prevState,
        questionId: item?.questionId,
        moduleId: item?.moduleId,
        questionType: item?.questionType,
        frequency: item?.frequency,
        proofDocumentNote: newProofDocument,
      };
    });
  };

  const saveComment = () => {
    // Save the comment and close the modal
    handleCommentChange(comments);

    setCommentModal(false);
  };
 
  const handleNoteChange = (e) => {
    const newNote = e.target.value;
    setNote([[newNote]]);
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      questionId: item?.questionId,
      moduleId: item?.moduleId,
      questionType: item?.questionType,
      frequency: item?.frequency,
      note: [[newNote]],
    }));
  };

  const handleAccept = async () => {
    const { isSuccess, error, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
      {},
      {
        questionId: item.questionId,
        answerId: answerIdReal,
        questionType: item.questionType,
        remark: remark,
        validation: "ACCEPTED",
        financialYearId: financeObject,
      },
      "POST"
    );

    if (isSuccess) {
      setRemark("");
      setAuditButton(true);
      getAuditListing();
    }

    if (error) {
      swal({
        icon: "error",
        title: data.message,
        timer: 1000,
      });
    }
  };

  const handleReject = async () => {
    if (remark) {
      const { isSuccess, error, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
        {},
        {
          questionId: item.questionId,
          answerId: answerIdReal,
          questionType: item.questionType,
          remark: remark,
          validation: "REJECTED",
          financialYearId: financeObject,
        },
        "POST"
      );

      if (isSuccess) {
        setRemark("");
        getAuditListing();
      }

      if (error) {
        swal({
          icon: "error",
          title: data.message,
          timer: 1000,
        });
      }
    } else {
      swal({
        icon: "error",
        title: "Please enter remark",
        timer: 1000,
      });
    }
  };

  const handleSubmit = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}saveAnswerReportingQuestion`,
      {},
      {
        ...answer,
        financialYearId: financeObject,
        subLocationId:selectedSubLocationId,
        answer: JSON.stringify({
          ...answerr,
          unit: item?.details[0]?.option,
        }),
      },
      "POST"
    );

    if (isSuccess) {
      setTarget(false);
      getReportingAnswer();
    }
    
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "20px",
      width: "100%",
    },
    text: {
      color: "rgba(0, 0, 0, 0.70)",
      fontSize: "12px",
      fontFamily: "Open Sans",
      fontWeight: 400,
    },
    uploadDiv: {
      width: "100%",
      backgroundColor: "#3F88A5",
      borderRadius: "10px",
      height: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      color: "white",
      position: "relative",
      border: "2px dashed #3F88A5",
      transition: "background-color 0.3s ease",
    },
    uploadDivHover: {
      backgroundColor: "#30707E", // Darker blue on hover
    },
    icon: {
      position: "absolute",
      right: "10px",
      fontSize: "10px",
      color: "white",
    },
    fileInput: {
      display: "none",
    },
    formGroup: {
      marginBottom: "15px",
    },
  };

  return (
    <Form>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {check &&
          assignedToDetails?.assignedTo?.length > 0 &&
          assignedToDetails.assignedTo.some(
            (id) => parseInt(id, 10) === currentUserId
          ) && (
            <FaEdit
              style={{
                cursor: "pointer",
                marginLeft: "10px",
                height: "20px",
                width: "20px",
                color: edit ? "black" : "#BFD7E0",
              }}
              onClick={handleEditClick}
            />
          )}
      </div>
      <Row>
        <Col md={2}>
          <Form.Group controlId="formInput11" style={styles.formGroup}>
            <Form.Label className="custom-label">
              Current Reading Value
            </Form.Label>
            <Form.Control
              readOnly={
                menu === "audit" ||
                (assignedToDetails?.assignedTo?.length > 0 &&
                  !assignedToDetails?.assignedTo?.some(
                    (id) => parseInt(id, 10) === currentUserId
                  )) ||
                (check && edit) ||
                (assignedToDetails?.dueDate &&
                  new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                    new Date().setHours(0, 0, 0, 0))
              }
              style={{ backgroundColor: "#Dfebef" }}
              type="text"
              onChange={handleReadingValue}
              value={readingValue || ""}
              onDoubleClick={() => handleDoubleClick(readingValue)}
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group controlId="formInput11" style={styles.formGroup}>
            <Form.Label className="custom-label">
              Previous Reading Value
            </Form.Label>
            <Form.Control
              readOnly={true}
              style={{ backgroundColor: "#Dfebef" }}
              type="text"
              value={preMonthReadingValue}
            />
          </Form.Group>
        </Col>
        {targetValue ? (
          <Col md={2}>
            <Form.Group controlId="formInput11" style={styles.formGroup}>
              <Form.Label className="custom-label">Target Value</Form.Label>
              <Form.Control
                readOnly={true}
                style={{ backgroundColor: "#Dfebef" }}
                type="text"
                value={targetValue}
              />
            </Form.Group>
          </Col>
        ) : (
          <></>
        )}

        <Col md={2}>
          <div>
            <label className="custom-label">Unit</label>
          </div>
          <select
            style={{
              backgroundColor: "#Dfebef",
              width: "80%",
              margin: "7px",
              height: "37px",
            }}
            disabled
            value={unit?.unit || item?.details[0]?.option}
          >
            <option>{unit?.unit || item?.details[0]?.option}</option>
          </select>
        </Col>

        <Col md={3}>
          {menu !== "audit" && (
            <Form.Group
              controlId="formFile"
              className="custom-file-upload"
              style={{ marginBottom: "5%" }}
            >
              <Form.Label className="custom-label">
                Upload Attachment
              </Form.Label>
              <div className="file-upload-wrapper">
                <label className="upload-btn">
                  <div style={{ height: "12px", width: "12px" }}>
                    <FaUpload height={"12px"} width={"12px"} />
                  </div>
                  <span>Upload a file</span>
                  <Form.Control
                    type="file"
                    disabled={
                      menu === "audit" ||
                      (assignedToDetails?.assignedTo?.length > 0 &&
                        !assignedToDetails?.assignedTo?.some(
                          (id) => parseInt(id, 10) === currentUserId
                        )) ||
                      (check && edit) ||
                      (assignedToDetails?.dueDate &&
                        new Date(assignedToDetails.dueDate).setHours(
                          0,
                          0,
                          0,
                          0
                        ) < new Date().setHours(0, 0, 0, 0))
                    }
                    style={{ display: "none" }} // Hide the default file input
                    onChange={(e) => uploadFile(e)}
                  />
                </label>
              </div>
            </Form.Group>
          )}

          <>
            {filteredDocs && filteredDocs.length > 0 && (
              <div
                style={{
                  backgroundColor: "#DFEBEF",
                  width: "100%",
                  padding: "5px",
                  borderRadius: "8px",
                  marginLeft: "0px",
                  marginTop: menu !== "audit" ? "0%" : "3%",
                }}
              >
                {filteredDocs?.length > 0 &&
                  filteredDocs?.map((url, index) => {
                    const docSegments = url.split("/");
                    const docFileName =
                      docSegments.length > 1
                        ? docSegments[docSegments.length - 2] ===
                          docSegments[docSegments.length - 1]
                          ? docSegments[docSegments.length - 1]
                          : docSegments[docSegments.length - 1]
                        : url;

                    let commentText;
                    if (
                      filteredComments.length > 0 &&
                      index < filteredComments.length
                    ) {
                      commentText =
                        filteredComments[index] !== 0
                          ? filteredComments[index]
                          : ""; // Check if commentText is 0
                    } else {
                      // Handle the case where the filteredComments array is empty or index is out of bounds
                      console.log(
                        "No valid comment found for the given index."
                      );
                    }

                    return (
                      <div key={index}>
                        <Col md={12}>
                          <div style={inputStyle}>
                            <span
                              style={{
                                wordBreak: "break-all",
                                flexGrow: 1,
                                fontSize: "12px",
                                maxWidth: "100%",
                                margin: "5px",
                              }}
                              title={docFileName}
                            >
                              {decodeURIComponent(docFileName).slice(0, 50)}
                            </span>

                            <IoDownloadOutline
                              style={{
                                marginLeft: 10,
                                cursor: "pointer",
                                height: "20px",
                                width: "20px",
                                color: "black",
                              }}
                              onClick={() => handleFileDownload(url)}
                              title="Download File"
                            />

                            {!(
                              menu === "audit" ||
                              (assignedToDetails?.assignedTo?.length > 0 &&
                                !assignedToDetails?.assignedTo?.some(
                                  (id) => parseInt(id, 10) === currentUserId
                                ))
                            ) && (
                              <RiDeleteBinLine
                                style={{
                                  cursor: "pointer",
                                  height: "20px",
                                  width: "20px",
                                  color: "black",
                                  marginLeft: "1rem",
                                }}
                                onClick={() => handleDeleteClick(url)}
                                title="Remove File"
                              />
                            )}
                          </div>
                        </Col>

                        <Col md={12}>
                          <div style={inputStyle}>
                            <Form.Control
                              type="text"
                              value={filteredComments[index] || ""} // If commentText is 0, it will be replaced by an empty string
                              onChange={(e) => handleDocCommentChange(e, index)}
                              readOnly={
                                menu === "audit" ||
                                (assignedToDetails?.assignedTo?.length > 0 &&
                                  !assignedToDetails?.assignedTo?.some(
                                    (id) => parseInt(id, 10) === currentUserId
                                  )) ||
                                (check && edit) ||
                                (assignedToDetails?.dueDate &&
                                  new Date(assignedToDetails.dueDate).setHours(
                                    0,
                                    0,
                                    0,
                                    0
                                  ) < new Date().setHours(0, 0, 0, 0))
                              }
                              placeholder="No Comment"
                              style={{
                                height: "100%",
                                width: "100%",
                                border: "none",
                                borderRadius: "5px",
                                border: "1.5px solid #3F88A5",
                                borderColor: "#3F88A5",
                                background: "#DFEBEF",
                                backgroundColor: "#DFEBEF",
                                paddingLeft: "10px",
                              }}
                            />
                          </div>
                        </Col>
                      </div>
                    );
                  })}
              </div>
            )}

            {/* Confirmation Modal */}
            <Modal show={showModal} onHide={cancelDelete} centered>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete this file?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={cancelDelete}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={confirmDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        </Col>

        {item?.note && (
          <Col md={12}>
            <Form.Group controlId="formInput12" style={styles.formGroup}>
              <Form.Label className="custom-label">Note</Form.Label>
              <Form.Control
                as="textarea"
                readOnly={
                  menu === "audit" ||
                  (assignedToDetails?.assignedTo?.length > 0 &&
                    !assignedToDetails?.assignedTo?.some(
                      (id) => parseInt(id, 10) === currentUserId
                    )) ||
                  (check && edit) ||
                  (assignedToDetails?.dueDate &&
                    new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                      new Date().setHours(0, 0, 0, 0))
                }
                style={{ backgroundColor: "#Dfebef" }}
                type="text"
                value={note[0][0]}
                onChange={handleNoteChange}
                onDoubleClick={() => handleDoubleClick(note)}
              />
            </Form.Group>
          </Col>
        )}

        <Col md={3}>
          <Form.Group controlId="formInput12">
            <Form.Label className="custom-label">Remark</Form.Label>
            <Form.Control
              as="textarea"
              style={{ backgroundColor: "#Dfebef" }}
              readOnly={
                menu === "audit" ||
                (assignedToDetails?.assignedTo?.length > 0 &&
                  !assignedToDetails?.assignedTo?.some(
                    (id) => parseInt(id, 10) === currentUserId
                  )) ||
                (check && edit) ||
                (assignedToDetails?.dueDate &&
                  new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                    new Date().setHours(0, 0, 0, 0))
              }
              type="text"
              value={remark}
              onChange={handleRemarkChange}
            />
          </Form.Group>
        </Col>
        {targetValue && (
          <>
            {readingValue > Number(targetValue) && (
              <p className="red-message">Your value has been exceeded</p>
            )}
            {readingValue === Number(targetValue) && (
              <p className="green-message">Goal Achieved.</p>
            )}
          </>
        )}

        <DetailModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          data={showData}
        />
        {emissionData?.energy?.value ? (
          <Col md={2}>
            <Form.Group controlId="formInput11" style={styles.formGroup}>
              <Form.Label className="custom-label">Energy</Form.Label>
              <Form.Control
                style={{ backgroundColor: "#Dfebef" }}
                type="text"
                value={`${emissionData?.energy?.value} ${emissionData?.energy?.unit}`}
                readOnly
              />
            </Form.Group>
          </Col>
        ) : (
          <></>
        )}
        {emissionData?.emission?.value ? (
          <Col md={2}>
            <Form.Group controlId="formInput11" style={styles.formGroup}>
              <Form.Label className="custom-label">Emission</Form.Label>
              <Form.Control
                style={{ backgroundColor: "#Dfebef" }}
                type="text"
                value={`${emissionData?.emission?.value} ${emissionData?.emission?.unit}`}
                readOnly
              />
            </Form.Group>
          </Col>
        ) : (
          <></>
        )}
        <Col md={2}>
          <button
            className="btn btn-secondary"
            style={{
              borderColor: "white",
              backgroundColor: "transparent",
              padding: "1%",
              paddingLeft: "1.5%",
              paddingRight: "1.5%",
            }}
          ></button>

          <div className="d-flex" style={{ justifyContent: "right" }}>
            {menu === "audit" ? (
              <>
                {auditItem &&
                  auditItem?.matchingAuditors.find(
                    (d) => d.answerId == answerIdReal
                  )?.auditerId == currentUserId &&
                  answerIdReal && (
                    <>
                      {auditItem?.matchingAuditors.find(
                        (d) => d.answerId == answerIdReal
                      )?.remark == null ||
                      auditItem?.matchingAuditors
                        .find((d) => d.answerId == answerIdReal)
                        ?.remark?.every(
                          (remark) => remark.id != currentUserId
                        ) ||
                      auditItem.matchingAuditors?.some(
                        (auditor) =>
                          Number(auditor?.auditerId) === Number(currentUserId)
                      ) ? (
                        <>
                          <button
                            className="btn"
                            onClick={handleAccept}
                            type="button"
                            style={{
                              borderColor: "white",
                              backgroundColor: "#3F88A5",
                              padding: "2%",
                              paddingLeft: "10%",
                              paddingRight: "10%",
                            }}
                          >
                            Accept
                          </button>
                          <button
                            className="btn"
                            onClick={handleReject}
                            type="button"
                            style={{
                              borderColor: "white",
                              backgroundColor: "#3F88A5",
                              padding: "1%",
                              paddingLeft: "10%",
                              paddingRight: "10%",
                            }}
                          >
                            Reject
                          </button>
                        </>
                      ) : status === "ACCEPTED" || status === "REJECTED" ? (
                        <p>This answer has been audited.</p>
                      ) : null}
                    </>
                  )}
              </>
            ) : (
              assignedToDetails?.assignedTo?.length > 0 &&
              assignedToDetails?.assignedTo?.some(
                (id) => parseInt(id, 10) === currentUserId
              ) && (
                <button
                  className="btn btn-primary"
                  onClick={() => handleSubmit(0)}
                  disabled={
                    item?.frequency === "CUSTOM"
                      ? !fromDate
                        ? true
                        : false
                      : false
                  }
                  style={{
                    borderColor: "white",
                    backgroundColor: "#3F88A5",
                    padding: "2%",
                    paddingLeft: "10%",
                    paddingRight: "10%",
                  }}
                >
                  Submit
                </button>
              )
            )}
          </div>
        </Col>
      </Row>
      <Modal show={confirmTarget} onHide={cancelTarget} size="lg" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formComment">
            <Form.Label>
              Reading value {`${readingValue}`} is greater than the target{" "}
              {`${target}`} set in the settings, you sure you want to continue?{" "}
            </Form.Label>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelTarget}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={commentModal} onHide={cancelComment} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Add a comment for file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formComment">
            <div>{file}</div>
            <Form.Label>Please provide a comment (optional):</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={comments}
              onChange={(e) => handleComment(e)}
              placeholder="Enter your comment here"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveComment}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export default TrendsComponent;
