import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import img from "../../../img/no.png"
const WaterHorizontalBar = ({ timePeriods, timePeriodValues, locationOption, brief }) => {
  const [chartOptions, setChartOptions] = useState(null); // Start with null
  const [series, setSeries] = useState([]);

  const categories = ["Groundwater consumption", "Tanker Water Consumption"];

  useEffect(() => {
    const locationPeriod = Object.keys(brief.time); // Assuming these are your locations
    const time = Object.keys(brief.location);

    // Prepare data for the chart based on conditions
    let seriesData = [];

    if (locationPeriod.length > 1 && timePeriodValues.length === 1) {
      // Case 1: Multiple locations and single time period
      locationPeriod.forEach(location => {
        const locationData = {};
        categories.forEach(category => {
          if (brief.time[location][category]) {
            // Take the first element of the array for the value
            locationData[category] = brief.time[location][category][0];
          }
        });
        seriesData.push(locationData);
      });
    } else if (locationPeriod.length === 1 && timePeriodValues.length > 1) {
      // Case 2: Single location and multiple time periods
      time.forEach(timeO => {
        const locationData = {};
        categories.forEach(category => {
          if (brief.location[timeO][category]) {
            // Take the first element of the array for the value
            locationData[category] = brief.location[timeO][category][0];
          }
        });
        seriesData.push(locationData);
      });
    } else if (locationPeriod.length > 1 && timePeriodValues.length > 1) {
      // Case 3: Multiple locations and multiple time periods
      time.forEach(timeO => {
        const locationData = {};
        categories.forEach(category => {
          if (brief.location[timeO][category]) {
            // Sum all the elements in the array for the value
            const sumValue = brief.location[timeO][category].reduce((acc, val) => acc + Number(val), 0);
            locationData[category] = sumValue;
          }
        });
        seriesData.push(locationData);
      });
    }

    // Convert seriesData into the format ApexCharts expects
    const chartSeries = categories.map(category => ({
      name: category,
      data: seriesData.map(item => item[category] || 0), // Fallback to 0 if no data
    }));

    // Update the state with the new series data
    setSeries(chartSeries);

    const options = {
      chart: {
        type: "bar",
        stacked: true,
        title: "",
        toolbar: {
          show: false, // Hide the three lines toolbar (download options)
        },
      },
      dataLabels: {
        enabled: true, // Enable data labels
        formatter: function (val) {
          return val; // Show the actual value
        },
        style: {
          fontSize: "12px",
          colors: ["#fff"], // Set text color to white for better contrast
        },
      },
      xaxis: {
        title: {
          text: '( in KL )',
          style: {
            fontSize: '14px',
            fontWeight: 500,
          },
        },
        categories:
          locationPeriod.length > 1 && timePeriodValues.length === 1
            ? locationPeriod // Using locations as X-axis categories for this condition
            : time, // Using time periods as X-axis categories for the other condition
        labels: {
          offsetY: -130, // This moves the labels upwards
          style: {
            fontSize: "12px",
            fontWeight: 500,
          },
        },
      },

      plotOptions: {
        bar: {
          horizontal: true, // Ensure horizontal bars
          barHeight: "30%",
          columnWidth: "30%",
        },
      },
      yaxis: {

      },
      title: {
        show: false,
        text: "",
      },
      legend: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
      colors: [
        "#587b87",
        "#11546f",
        "#ABC4B2",
        "#6D8B96",
        "#9CDFE3",
        "#11546f",
        "#587b87",
        "#8CBBCE",
      ],
      dataLabels: {
        enabled: false,
      },
    };



    setChartOptions(options);
  }, [brief, locationOption, timePeriods]);

  // Check if all series values are zero
  const allValuesZero = series.every(item => item.data.every(value => value === 0));

  if (!brief || !brief.time) {
    return <p>No data available.</p>;
  }

  return (
    <div className="container">
      <div
        style={{
          height: "10%",
          fontSize: "20px",
          fontWeight: 600,
          color: "#011627",

        }}
      >
        Total Water Consumption
      </div>

      <div style={{ height: "70%" }}>
        {allValuesZero ? (
          <div style={{ height: "100%", width: "100%", display: "flex", alignItems: 'center', justifyContent: 'center' }}>

            <img src={img} style={{ height: "90px", width: "170px" }} />



          </div>
        ) : (
          chartOptions && (
            <Chart
              options={chartOptions}
              series={series}
              type="bar"
              height={"100%"}
            />
          )
        )}
      </div>

      {/* Custom Legends Container */}
      {chartOptions && (
        <div style={{ marginTop: "20px", width: "100%" }}>
          {categories
            .reduce((acc, category, index) => {
              if (index % 3 === 0) acc.push([]); // Start a new row for every three items
              acc[acc.length - 1].push(
                <div
                  key={index}
                  style={{
                    display: "flex",
                    width: "25%",
                    alignItems: "center",
                    marginRight: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      backgroundColor: chartOptions.colors[index], // Use corresponding color
                      marginRight: "5px",
                    }}
                  />
                  <span style={{ fontWeight: 300, fontSize: "12px" }}>
                    {category}
                  </span>
                </div>
              );
              return acc;
            }, [])
            .map((row, rowIndex) => (
              <div
                key={rowIndex}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  fontSize: "12px",
                  marginBottom: "10px",
                }}
              >
                {row}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default WaterHorizontalBar;
