import React, { useState, useEffect } from 'react';

// A small helper function to format "YYYY-MM" into a readable "Month YYYY" string.
const formatDate = (dateString) => {
    if (!dateString) {
        return ""
    }
    // Special handling for weird inputs like "2023-00"
    // (we treat "2023-00" as if it were "2023-01")
    const [yearStr, monthStr] = dateString === '2023-00' ? ['2023', '01'] : dateString.split('-');
    const year = parseInt(yearStr, 10);
    // Month in JavaScript's Date constructor is zero-based, so "02" => 1
    const month = parseInt(monthStr, 10) - 1;

    // If month is negative or something unexpected, default to 0
    const safeMonth = isNaN(month) || month < 0 ? 0 : month;
    const date = new Date(year, safeMonth);

    // Return a short month-year, like "Jan 2023"
    return date.toLocaleString('default', { month: 'short', year: 'numeric' });
};

const Excel = ({ data }) => {
    const [processedData, setProcessedData] = useState({});
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        const processData = () => {
            const newData = {};
            const dateColumns = new Set();

            data.forEach((item) => {
                const { questionId, fromDate, toDate, notApplicable, answer } = item;

                //Avoid Json parse error in case of empty answer
                if (!answer || answer === '') {
                    return;
                }

                let value = "";
                let parsedAnswer = JSON.parse(answer);

                console.log("Not Applicable: "+notApplicable)
                //Only if it is not applicable and also the readingValue is empty
                if (notApplicable==="true" && !parsedAnswer.readingValue) {
                    value = "NA";
                    console.log("*****************************************************************************Not applicable value: "+JSON.stringify(parsedAnswer))
                }
                else {
                    // readingValue is the actual numeric value
                    value = parsedAnswer.readingValue;
                }

                // questionTitle is the description
                const questionTitle = parsedAnswer.questionTitle;

                // Decide which date to use for the table column
                const columnDate = fromDate === '2023-00' ? '2023-01' : fromDate;
                const formattedDate = formatDate(columnDate);

                dateColumns.add(formattedDate);

                // If the questionId doesn't exist yet, initialize it
                if (!newData[questionId]) {
                    newData[questionId] = {
                        title: questionTitle,
                        values: {},
                    };
                }

                // Store the value in the correct date column
                newData[questionId].values[formattedDate] = value;
            });

            // 1) Define your mandatory date range in "YYYY-MM" format
            const mandatedDates = [
                "2024-04", "2024-05", "2024-06",
                "2024-07", "2024-08", "2024-09",
                "2024-10", "2024-11", "2024-12"
            ];

            // 2) Group readingValue by userId and fromDate
            const userData = {};
            data.forEach((item) => {
                const { userId, fromDate, answer } = item;
                //Avoid Json parse error in case of empty answer
                if (!answer || answer === '') {
                    return;
                }
                const parsed = JSON.parse(answer);
                const readingValue = parsed.readingValue;

                if (!userData[userId]) userData[userId] = {};
                userData[userId][fromDate] = readingValue;
            });

            // 3) Combine mandatory dates with any other found in your data
            //    so columns always include 2024-04..12 plus all extra ones you have
            const dateSet = new Set(mandatedDates);
            data.forEach(a => dateSet.add(a.fromDate));
            const columns = Array.from(dateSet).sort(/* sort by date */);

            // 2) After collecting all dates from data.answers, add the mandated dates
            mandatedDates.forEach((md) => {
                dateColumns.add(formatDate(md));
            });

            // Convert dateColumns to an array and sort them by actual date
            const sortedColumns = Array.from(dateColumns).sort((a, b) => {
                return new Date(a) - new Date(b);
            });

            setProcessedData(newData);
            setColumns(sortedColumns);
        };

        processData();
    }, [data]);

    return (
        <div style={{ overflowX: 'auto' }}>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                    <tr>
                        <th style={thStyle}>Question</th>
                        {columns.map((col) => (
                            <th key={col} style={thStyle}>
                                {col}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(processedData).map(([questionId, questionData]) => (
                        <tr key={questionId}>
                            <td style={tdStyle}>{questionData.title}</td>
                            {columns.map((col) => (
                                <td key={col} style={tdStyle}>
                                    {questionData.values[col] || '-'}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

// Just for a bit of inline styling (you can remove this if you prefer CSS)
const thStyle = {
    border: '1px solid #ccc',
    padding: '8px',
    background: '#f2f2f2',
    textAlign: 'left',
};

const tdStyle = {
    border: '1px solid #ccc',
    padding: '8px',
};

export default Excel;
