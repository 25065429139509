import React, { useState } from "react";
import Close from "../img/Close.png";

const TrainingFilterModal = ({
  onClose,
  topicMapping,
  setTopicMapping,
  trainingTopicData,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTopicChange = (topic) => {
    const isAlreadySelected = topicMapping.some(
      (selectedTopic) => selectedTopic.id === topic.id
    );

    let updatedTopics;
    if (isAlreadySelected) {
      updatedTopics = topicMapping.filter(
        (selectedTopic) => selectedTopic.id !== topic.id
      );
    } else {
      updatedTopics = [...topicMapping, topic];
    }

    setTopicMapping(updatedTopics);
  };

  const handleRemoveSelectedTopic = (topicId) => {
    const updatedTopics = topicMapping.filter(
      (selectedTopic) => selectedTopic.id !== topicId
    );
    setTopicMapping(updatedTopics);
  };

  const filteredTopics = trainingTopicData.filter((topic) =>
    topic.topic?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const isTopicSelected = (topicId) =>
    topicMapping.some((selectedTopic) => selectedTopic.id === topicId);

  return (
    <>
      <div className="modal-backdrop" style={modalBackdropStyles()}>
        <div className="modal-content" style={modalContentStyles()}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1em",
            }}
          >
            <div>
              <h2 style={headingStyle()}>Topic Filter</h2>
            </div>
            <div>
              <img
                src={Close}
                alt="close"
                style={{ width: "20px", cursor: "pointer" }}
                onClick={onClose}
              />
            </div>
          </div>

          {/* Search Input */}
          <div style={{ marginBottom: "1em" }}>
            <input
              type="text"
              placeholder="Search topics"
              value={searchTerm}
              onChange={handleSearchChange}
              style={searchInputStyle()}
            />
          </div>

          {/* Selected Topics Box */}
          {topicMapping.length > 0 && (
            <div style={selectedTopicsBoxStyle()}>
              {topicMapping.map((topic) => (
                <div key={topic.id} style={selectedTopicItemStyle()}>
                  <span>{topic.topic}</span>
                  <button
                    style={removeButtonStyle()}
                    onClick={() => handleRemoveSelectedTopic(topic.id)}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          )}

          {/* Training Topic List */}
          <div style={trainingTopicContainerStyle()}>
            <div style={{ fontSize: "16px", marginBottom: "4px" }}>
              Training Topics
            </div>
            {filteredTopics.length > 0 ? (
              filteredTopics.map((topic) => (
                <div
                  key={topic.id}
                  style={{ marginBottom: "10px", fontSize: "14px" }}
                >
                  <label style={{ margin: "4px" }}>
                    <input
                      type="checkbox"
                      style={checkboxStyle()}
                      checked={isTopicSelected(topic.id)}
                      onChange={() => handleTopicChange(topic)}
                    />
                    {topic?.topic}
                  </label>
                </div>
              ))
            ) : (
              <p>No topics available for your search term.</p>
            )}
          </div>
          <div className="text-end">
            <button className="btn-primary" onClick={onClose}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const modalBackdropStyles = () => ({
  position: "fixed",
  top: "20%",
  left: 0,
  width: "100%",
  height: "55vh",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  backdropFilter: "blur(10px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const modalContentStyles = () => ({
  background: "white",
  padding: "20px",
  borderRadius: "5px",
  width: "50%",
  overflowY: "auto",
  maxHeight: '500px',
});

const headingStyle = () => ({
  fontSize: "24px",
  marginBottom: "15px",
});

const searchInputStyle = () => ({
  width: "100%",
  padding: "10px",
  borderRadius: "5px",
  border: "1px solid #ccc",
  fontSize: "16px",
});

const trainingTopicContainerStyle = () => ({
  overflow: "auto",
  marginTop: "20px",
  paddingLeft: "20px",
});

const checkboxStyle = () => ({
  width: "10px",
  height: "10px",
  transform: "scale(1.5)",
  marginRight: "10px",
  border: "2px solid #3F88A5",
  backgroundColor: "#3F88A5",
  borderRadius: "4px",
  position: "relative",
});

const selectedTopicsBoxStyle = () => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
  backgroundColor: "#f9f9f9",
  padding: "10px",
  borderRadius: "5px",
  marginBottom: "15px",
  height: '52rem',
  maxHeight: '52rem',
  overflowY: 'auto'
});

const selectedTopicItemStyle = () => ({
  backgroundColor: "#3F88A5",
  color: "white",
  padding: "5px 10px",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  height: "3rem"
});

const removeButtonStyle = () => ({
  background: "none",
  border: "none",
  color: "white",
  fontSize: "16px",
  cursor: "pointer",
});

export default TrainingFilterModal;
