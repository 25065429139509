import React, { Component } from "react";
import config from "../../../../config/config.json";
import { apiCall } from "../../../../_services/apiCall";
import ParentExcel from "./parentexcel";

export default class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: [], // Start with an empty array
      loadingQuestions: true, // Flag for loading questions
      fetchingAnswers: false, // Flag for fetching answers incrementally
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const financialYearId = 30;
    const frameworkIds = [48];

    try {
      // Step 1: Fetch questions
      const questionResponse = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getReportingQuestion`,
        {},
        { financialYearId, frameworkIds },
        "GET"
      );

      let questionIds = [];

      // Check if `data` is an array or an object
      if (Array.isArray(questionResponse.data)) {
        questionIds = questionResponse.data.map((question) => question.questionId);
      } else if (typeof questionResponse.data === "object" && questionResponse.data !== null) {
        questionIds = Object.values(questionResponse.data.data).map(
          (question) => question.questionId
        );
      } else {
        console.error("Unexpected data format:", questionResponse.data);
      }

      if (questionIds.length === 0) {
        throw new Error("No questions found");
      }

      // Set loadingQuestions to false and start fetching answers
      this.setState({ loadingQuestions: false, fetchingAnswers: true });

      // Step 2: Fetch answers for each question ID incrementally
      for (const questionId of questionIds) {
        const answerResponse = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getReportingAnswer`,
          {},
          { financialYearId, questionId },
          "GET"
        );

        if (answerResponse.isSuccess) {
          this.setState((prevState) => ({
            answers: [...prevState.answers, ...(answerResponse.data?.answers || [])],
          }));
        }
      }

      // Set fetchingAnswers to false after all questions are processed
      this.setState({ fetchingAnswers: false });
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ loadingQuestions: false, fetchingAnswers: false });
    }
  }

  render() {
    const { loadingQuestions, fetchingAnswers, answers } = this.state;

    // Show loading message while questions are being fetched
    if (loadingQuestions) {
      return <div>Loading questions...</div>;
    }

    return (
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Entered values</h1>
        {fetchingAnswers && <div>Fetching data incrementally...</div>}
        <ParentExcel allAnswers={answers} />
      </div>
    );
  }
}
