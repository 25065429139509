import React from "react";
import img from "../../../img/no.png"

const TrainingBarFourtyEightTwo = ({

  brief,
}) => {
  // Function to extract and sum category values from the brief
  const getCategorySums = (categoryKey) => {
    let totalForCategory = 0;
    // Loop through each location in the 'time' key
    if (brief && brief.time) {
      // Loop through each location in the 'time' key
      Object.keys(brief.time).forEach((location) => {
        const categoryValues = brief.time[location][categoryKey];
        if (categoryValues) {
          // Sum up values for the category across all time periods
          totalForCategory += categoryValues.reduce(
            (acc, value) => acc + value,
            0
          );
        }
      });
    }

    return totalForCategory;
  };

  // Example categories (replace with actual categories from brief)
  const categories = [
    "Safety Trainings",
    "Safety Committee Meetings"
  ];
  const adjustAndRoundTotalSum = (totalSum) => {
    if (totalSum < 1) {
      // Handle small decimals
      if (totalSum < 0.01) {
        // Round to nearest 0.005 for values below 0.01
        return Math.ceil(totalSum * 200) / 200; // (0.005 increments)
      } else if (totalSum < 0.1) {
        // Round to nearest 0.01 for values between 0.01 and 0.1
        return Math.ceil(totalSum * 100) / 100; // (0.01 increments)
      } else {
        // Round to nearest 0.5 or 1 for values between 0.1 and 1
        return Math.ceil(totalSum * 2) / 2; // (0.5 increments)
      }
    }

    const totalStr = totalSum.toString(); // Convert number to a string
    const firstTwoDigits = parseInt(totalStr.slice(0, 2)); // Extract the first two digits
    const magnitude = Math.pow(10, totalStr.length - 2); // Calculate the magnitude based on the number of digits

    // Apply custom rounding logic based on the first two digits
    if (firstTwoDigits > 75) {
      return 100 * magnitude; // Round to 100
    } else if (firstTwoDigits > 50) {
      return 75 * magnitude; // Round to 75
    } else if (firstTwoDigits > 25) {
      return 50 * magnitude; // Round to 50
    } else if (firstTwoDigits > 10) {
      return 25 * magnitude; // Round to 25
    } else {
      return 10 * magnitude; // Round to 10
    }
  };

  // Calculate total sums for each category
  const categoryValues = categories.map((category) => ({
    category,
    totalValue: getCategorySums(category),
  }));

  // Calculate the grand total sum of all categories
  const totalSum = adjustAndRoundTotalSum(categoryValues.reduce(
    (acc, item) => acc + item.totalValue,
    0
  ))


  // Colors for each category
  const colors = [
    "#C6CB8D",
    "#949776",
    "#ABC4B2",
    "#6D8B96",
    "#9CDFE3",
    "#11546f",
    "#587b87",
    "#8CBBCE",
  ];

  const shortenCategory = (category) => {
    const shortenedMap = {
      "Safety Trainings": "Trainings",
      " Safety Committee Meetings": "Committee Meetings",



    };

    return shortenedMap[category] || category; // Fallback to full category if not found
  };

  const chunkArray = (arr, chunkSize) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

  return (
    <div className="container" style={{ width: "100%" }}>
      <div
        style={{
          height: "10%",
          fontSize: "20px",
          fontWeight: 600,
          color: "#011627",
          marginBottom: "3%",
        }}
      >
        Average Trainings of Employees
      </div>
      {categoryValues.length > 0 && totalSum > 0 ? (
        <>
          <div className="renewable-bar-labels">
            <span style={{ fontSize: "11px", fontWeight: 600 }}>0</span>
            <span style={{ fontSize: "11px", fontWeight: 600 }}>
              {(totalSum / 5)}
            </span>
            <span style={{ fontSize: "11px", fontWeight: 600 }}>
              {((totalSum / 5) * 2)}
            </span>
            <span style={{ fontSize: "11px", fontWeight: 600 }}>
              {((totalSum / 5) * 3).toFixed(0)}
            </span>
            <span style={{ fontSize: "11px", fontWeight: 600 }}>
              {((totalSum / 5) * 4)}
            </span>
            <span style={{ fontSize: "11px", fontWeight: 600 }}>
              {(totalSum)}
            </span>
          </div>
          <div className="renewable-bar-dotted-line"></div>


          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "30px",
                border: "1px solid #ccc",
              }}
            >
              {categoryValues
                .filter((item) => item.totalValue > 0) // Filter out zero values
                .map((item, index) => (
                  <div
                    key={index}
                    style={{
                      width: `${(item.totalValue / totalSum) * 100}%`,
                      backgroundColor: colors[index % colors.length],
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: "12px",
                    }}
                    title={`${item.category}: ${item.totalValue}`}
                  >

                  </div>
                ))}
            </div>
            <div>
              {/* Split categoryValues into chunks of 3 */}
              {chunkArray(categoryValues.filter((item) => item.totalValue > 0), 3).map(
                (chunk, chunkIndex) => (
                  <div
                    key={`chunk-${chunkIndex}`} // Use a unique key for each chunk
                    style={{
                      display: "flex",
                      marginTop: "15px",
                      width: "100%",
                    }}
                  >
                    {chunk.map((item, index) => (
                      <div
                        key={`item-${chunkIndex}-${index}`} // Use a unique key for each item
                        style={{
                          width: "40%",
                          display: "flex",
                          fontSize: "12px",
                          alignItems: "center",
                          marginRight: "15px",
                        }}
                      >
                        <div
                          style={{
                            width: "20%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "15px",
                              height: "15px",
                              borderRadius: "50%",
                              backgroundColor:
                                colors[(chunkIndex * 3 + index) % colors.length],
                              marginRight: "5px",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            width: "80%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div style={{ fontSize: "12px" }}>
                            {shortenCategory(item.category)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
          </div>
        </>) : (
        <div style={{ height: "100%", width: "100%", display: "flex", alignItems: 'center', justifyContent: 'center', marginTop: "-4%" }}>

          <img src={img} style={{ height: "120px", width: "170px" }} />



        </div>
      )}
    </div>

  );
};

export default TrainingBarFourtyEightTwo;
