import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./accordioncomponent.css";
import "react-datepicker/dist/react-datepicker.css";
import MainAccordComponent from "./MainAccordComponent";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
const AccordionComponent = ({
  getAuditListing,
  sourceData,
  currentUserId,
  menu,
  apiData,
  financeObject,
  moduleData,
  financialYear,
  startingMonth,
  assignedTo,
  correctModuleId,
  units
}) => {
  // console.log(moduleData,"moduleDatamoduleData")
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [savedAnswers, setSavedAnswers] = useState([{}]);
  const [userData, setUserData] = useState([]);
  const [moduleIdToUse, setModuleIdToUse] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [applicable, setApplicable] = useState(true);

  const handleDateChange = (date) => {
    setStartDate(date);
    setIsOpen(false); // Close the calendar after selecting a date
  };

  const handlePeriodSelect = (key) => {
    setSelectedPeriod(key);
  };

  const handleCalendarOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const isMounted = useRef(true);

  useEffect(() => {
    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      isMounted.current = false;
    };
  }, []);



  const getAllUser = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getAllUser`,
      {},
      {},
      "GET"
    );
    if (isSuccess && isMounted.current) {
      setUserData(data.data);
    }
  };


  // const getReportingQuestionAnswer = async () => {
  //   if (moduleIdToUse && moduleIdToUse.length && financeObject) {
  //     const { isSuccess, data } = await apiCall(
  //       `${config.POSTLOGIN_API_URL_COMPANY}getReportingQuestionAnswer`,
  //       {},
  //       { moduleId: moduleIdToUse, financialYearId: financeObject },
  //       "GET"
  //     );
  //     if (isSuccess && isMounted.current) {
  //       setSavedAnswers(data.answers);
  //     }
  //   }
  // };

  // const getFinancialYear = async () => {
  //   const { isSuccess, data } = await apiCall(
  //     `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
  //     {},
  //     {}
  //   );

  //   if (isSuccess && isMounted.current) {
  //     getReportingQuestionAnswer(data.data[data?.data?.length - 1].id)
  //   }
  // };

  useEffect(() => {
    const correctModuleIdValue =
      Array.isArray(correctModuleId) && correctModuleId.length === 0
        ? menu === "audit"
          ? localStorage.getItem("auditModuleId")
          : localStorage.getItem("moduleId")
        : correctModuleId;
    const moduleIdToUse =
      correctModuleIdValue ||
      (menu === "audit"
        ? localStorage.getItem("auditModuleId")
        : localStorage.getItem("moduleId"));
    setModuleIdToUse(moduleIdToUse);
  }, []);

  useEffect(() => {
    setActiveIndex(null);
    // getReportingQuestionAnswer();
  }, [moduleData, financeObject]);

  useEffect(() => {
    const correctModuleIdValue =
      Array.isArray(correctModuleId) && correctModuleId.length === 0
        ? menu === "audit"
          ? localStorage.getItem("auditModuleId")
          : localStorage.getItem("moduleId")
        : correctModuleId;
    const moduleIdToUse =
      correctModuleIdValue ||
      (menu === "audit"
        ? correctModuleId === undefined ? localStorage.getItem("auditModuleId") : correctModuleId
        : localStorage.getItem("moduleId"));
    setModuleIdToUse(moduleIdToUse || correctModuleId);
  }, [correctModuleId]);

  useEffect(() => {
    getAllUser();
  }, []);

  // useEffect(() => {
  //   if (moduleIdToUse) {
  //     getReportingQuestionAnswer();
  //   }
  // }, [moduleIdToUse, financeObject]);

  return (
    <div
      className="container w-100 my-3"
      style={{ background: "transparent", padding: "0%" }}
    >
      <div className="accordion" id="accordionExample">
        {moduleData && moduleData.length > 0 ? (
          moduleData.map((item, index) => (
            <MainAccordComponent
              key={index}
              getAuditListing={getAuditListing}
              sourceData={sourceData}
              currentUserId={currentUserId}
              menu={menu}
              assignedTo={assignedTo}
              apiData={apiData}
              savedAnswers={savedAnswers}
              moduleId={correctModuleId}
              userData={userData}
              financeObject={financeObject}
              applicable={applicable}
              item={item}
              index={index}
              activeIndex={activeIndex}
              handleAccordionClick={handleAccordionClick}
              startingMonth={startingMonth}
              financialYear={financialYear}
              handlePeriodSelect={handlePeriodSelect}
              selectedPeriod={selectedPeriod}
              handleDateChange={handleDateChange}
              handleCalendarOpen={handleCalendarOpen}
              isOpen={isOpen}
              setApplicable={setApplicable}
              startDate={startDate}
              units={units}
            />
          ))
        ) : (
          <div>No data available</div>
        )}
      </div>
    </div>
  );
};

export default AccordionComponent;
