import React, { useState, useEffect } from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";

const Triggers = () => {
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [frameworkIds, setFrameworkIds] = useState([]);
  const [questions, setQuestions] = useState({});
  const [selectedFinancialYear, setSelectedFinancialYear] = useState(""); // New State for Financial Year
  const [financialYears, setFinancialYears] = useState([]); // Store Financial Years
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [triggerValues, setTriggerValues] = useState({});
  const [unitOptions, setUnitOptions] = useState([]);
  const [identifier, setIdentifier] = useState();
  const [timePeriodOptions, setTimePeriodOptions] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [apiData, setApiData] = useState();

  const getFrequency = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFrequency`,
      {},
      { financialYearId: selectedFinancialYear },
      "GET"
    );
    if (isSuccess) {
      setIdentifier(data.data);
    }
  };
  // Fetch Financial Years and set the latest one by default
  const getFinancialYears = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
        {},
        {}
      );
      if (isSuccess && data?.data.length > 0) {
        setFinancialYears(data.data);
        setSelectedFinancialYear(data.data[data.data.length - 1]?.id); // Auto-select last value
      }
    } catch (error) {
      console.error("Error fetching financial years:", error);
    }
  };

  // Fetch Locations
  const getSource = async () => {
    try {
      const response = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
        {},
        {},
        "GET"
      );
      if (response.isSuccess) {
        setLocations(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  // Fetch Categories
  const getUnitCategory = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getUnitCatagory`,
        {},
        { type: "ALL" },
        "GET"
      );

      if (isSuccess) {
        const filteredCategories = data.data.filter((item) => item.id !== 1); // Remove id === 1
        setCategories(filteredCategories);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Fetch Framework Data
  const fetchFrameworkApi = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getFramework`,
        {},
        { type: "ALL" }
      );
      if (isSuccess) {
        setFrameworkIds(data?.data.map((item) => item.id));
        return data?.data.map((item) => item.id);
      }
    } catch (error) {
      console.error("Error fetching framework data:", error);
    }
  };

  // Fetch Save Data
  const saveTriggerData = async (questionId, value) => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}saveTriggerData`,
        {},
        {
          questionId,
          questionTitle: "ABC",
          financialYearId: selectedFinancialYear,
          sourceId: Number(selectedLocation),
          fromDate,
          toDate,
          unit: triggerValues[questionId]?.unit,
          answer: value,
          questionType: "quantitative_trends",
        },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {}
  };

  const getTargetQuestionAnswer = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTargetQuestionAnswer`,
      {},
      { financialYearId: selectedFinancialYear },
      "GET"
    );
    if (isSuccess) {
      setApiData(data.answers);
    }
  };
  useEffect(() => {
    if (selectedFinancialYear) {
      getTargetQuestionAnswer();
    }
  }, [selectedFinancialYear]);

  // Fetch Reporting Questions Based on Selected Financial Year
  const getReportingQuestions = async () => {
    try {
      if (!selectedFinancialYear) return;
      const frameworkIds = await fetchFrameworkApi();
      if (!frameworkIds?.length) return;
      console.log(frameworkIds, frameworkIds.includes(48));
      if (frameworkIds.includes(48)) {
        const response = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getReportingQuestion`,
          {},
          { financialYearId: selectedFinancialYear, frameworkIds },
          "GET"
        );

        if (response.isSuccess) {
          const data = response.data;
          const groupedByModuleName = data.data
            .filter((item) => item.categoryId != 1) // Remove categoryId 1
            .reduce((acc, item) => {
              if (!acc[item.categoryId]) {
                acc[item.categoryId] = [];
              }
              acc[item.categoryId].push(item);
              return acc;
            }, {});

          setQuestions(groupedByModuleName);
        }
      } else {
        const response = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getSetTargetQuestion`,
          {},
          {},
          "GET"
        );
        if (response.isSuccess) {
          const dataa = response.data.data;
          setCategories(dataa);
          setQuestions(dataa);
        }
      }
    } catch (error) {
      console.error("Error fetching reporting questions:", error);
    }
  };

  // Handle category selection and set units accordingly
  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    const selectedCategory = categories.find(
      (cat) => cat.id === Number(categoryId)
    );
    if (selectedCategory && selectedCategory.units) {
      setUnitOptions(selectedCategory.units);
    }
  };

  // Handle frequency, unit, and trigger value changes
  const handleChange = (questionId, field, value) => {
    setTriggerValues((prev) => ({
      [questionId]: { ...prev[questionId], [field]: value },
    }));
    if (field === "triggerValue") {
      saveTriggerData(questionId, value);
    }
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const start = JSON.parse(localStorage.getItem("currentUser")).starting_month;

  const calculateDateRange = (type, period, startingMonth, year) => {
    const startMonth = ((startingMonth - 1 + (period - 1) * type) % 12) + 1;
    const startYear =
      year + Math.floor((startingMonth - 1 + (period - 1) * type) / 12);
    const endMonth = ((startMonth - 1 + type) % 12) + 1;
    const endYear = startYear + Math.floor((startMonth - 1 + type) / 12);

    const formatDate = (month, year) =>
      `${year}-${month < 10 ? `0${month}` : month}`;

    return {
      fromDate: formatDate(startMonth, startYear),
      toDate: formatDate(endMonth, endYear),
    };
  };

  useEffect(() => {
    if (identifier) {
      let options = [];
      if (identifier === "MONTHLY") {
        const options =
          start === 1
            ? months
            : [...months.slice(start - 1), ...months.slice(0, start - 1)];

        // Map options to { label, value } format
        const formattedOptions = options.map((month, index) => ({
          label: month,
          value: ((start + index - 1) % 12) + 1, // Ensures values cycle correctly from 1 to 12
        }));

        setTimePeriodOptions(formattedOptions); // Update state
      } else if (identifier === "QUARTERLY") {
        for (let i = start - 1; i < start + 11; i += 3) {
          const quarterStartIndex = i % 12;
          const quarterEndIndex = (i + 3) % 12;
          const quarter = `${months[quarterStartIndex]} - ${
            months[(quarterEndIndex - 1 + 12) % 12]
          }`;
          options.push({ label: quarter, value: options.length + 1 });
        }
        setTimePeriodOptions(options);
      } else if (identifier === "HALF_YEARLY") {
        for (let i = start - 1; i < start + 11; i += 6) {
          const halfStartIndex = i % 12;
          const halfEndIndex = (i + 6) % 12;
          const half = `${months[halfStartIndex]} - ${
            months[(halfEndIndex - 1 + 12) % 12]
          }`;
          options.push({ label: half, value: options.length + 1 });
          setTimePeriodOptions(options);
        }
      } else if (identifier === "YEARLY") {
        const yearlyStartIndex = start - 1;
        options = [
          {
            label: `${months[yearlyStartIndex]} - ${
              months[(yearlyStartIndex - 1 + 12) % 12]
            }`,
            value: 1,
          },
        ];
        setTimePeriodOptions(options);
      }
    }
  }, [identifier, start]);

  const handlePeriodChange = (questionId, field, value) => {
    const years = financialYears.find(
      (item) => item.id === selectedFinancialYear
    ).financial_year_value;

    const year = parseInt(years.split("-")[0]);

    let earliestFromDate = null;
    let latestToDate = null;

    let dateRange;

    if (identifier === "HALF_YEARLY") {
      const sixMonthLater = (start + 6) % 12; // Wrap around December if necessary

      const halfYear = sixMonthLater === (value + 1) % 12 ? 2 : 1;

      dateRange = calculateDateRange(6, halfYear, start, year);
    } else if (identifier === "QUARTERLY") {
      const quarter = Math.floor(value / 3) + 1;

      dateRange = calculateDateRange(3, quarter, start, year);
    } else if (identifier === "MONTHLY") {
      const startIndex = start - 1;
      const firstMonthIndex =
        (value - startIndex + months.length) % months.length;
      dateRange = calculateDateRange(1, firstMonthIndex, start, year);
    } else if (identifier === "YEARLY") {
      dateRange = calculateDateRange(12, 1, start, year);
    }
    if (dateRange) {
      // newTimePeriods[period?.label] = dateRange?.fromDate;

      if (
        !earliestFromDate ||
        new Date(dateRange.fromDate) < new Date(earliestFromDate)
      ) {
        earliestFromDate = dateRange.fromDate;
      }
      if (
        !latestToDate ||
        new Date(dateRange.toDate) > new Date(latestToDate)
      ) {
        latestToDate = dateRange.toDate;
      }
    }

    setFromDate(earliestFromDate);
    setToDate(latestToDate);
    setTriggerValues((prev) => ({
      [questionId]: { ...prev[questionId], [field]: value },
    }));
  };
  const handleTabularChangeChange = (
    questionId,
    rowId,
    columnId,
    key,
    value
  ) => {
    // Create the structured data first
    const updatedValues = {
      [questionId]: {
        [rowId]: {
          [columnId]: {
            [key]: value,
          },
        },
      },
    };
  console.log(updatedValues)
    // Set the state with the structured data
    setTriggerValues(() => updatedValues);
  
    // If the key is "triggerValue", save the data
    if (key === "triggerValue") {
      saveTriggerData(questionId, updatedValues[questionId][rowId][columnId][key]);
    }
  };
  

  useEffect(() => {
    getFinancialYears();
    getSource();
    getUnitCategory();
  }, []);

  useEffect(() => {
    if (selectedFinancialYear) {
      getFrequency();
      getReportingQuestions();
    }
  }, [selectedFinancialYear]);

  return (
    <div
      style={{
        background: "white",
        borderRadius: "15px",
        width: "100%",
        padding: "2rem",
        overflow: "auto",
      }}
    >
      <div
        className="d-flex justify-content-between buttoncont"
        style={{
          marginBottom: "25px",
          width: "71vw",
          overflow: "auto",
          gap: "10px",
        }}
      >
        {/* Financial Year Dropdown (Auto-Select Last Value) */}
        <select
          value={selectedFinancialYear}
          onChange={(e) => setSelectedFinancialYear(e.target.value)}
          className="form-select"
        >
          <option value="">Select Financial Year</option>
          {financialYears.map((year, index) => (
            <option key={index} value={year.id}>
              {year.financial_year_value}
            </option>
          ))}
        </select>

        {/* Location Dropdown */}
        <select
          value={selectedLocation}
          onChange={(e) => setSelectedLocation(e.target.value)}
          className="form-select"
        >
          <option value="">Select Location</option>
          {locations.map((location, index) => (
            <option key={index} value={location.id}>
              {location?.unitCode ||
                `${location?.location?.area || ""}, ${
                  location?.location?.city || ""
                }, ${location?.location?.state || ""}, ${
                  location?.location?.country || ""
                }`.trim()}
            </option>
          ))}
        </select>

        {/* Category Dropdown */}
        <select
          value={selectedCategory}
          onChange={(e) => handleCategoryChange(e.target.value)}
          className="form-select"
        >
          <option value="">Select Category</option>
          {categories.map((category, index) => (
            <option key={index} value={category.id}>
              {category?.title}
            </option>
          ))}
        </select>
      </div>

      {frameworkIds && frameworkIds.length > 0 && frameworkIds.includes(48)
        ? selectedCategory &&
          questions[selectedCategory] && (
            <div className="questions-container">
              <table className="table">
                <thead>
                  <tr>
                    <th>Question Title</th>
                    <th>Frequency</th>
                    <th>Unit</th>
                    <th>Trigger Value</th>
                  </tr>
                </thead>
                <tbody>
                  {questions[selectedCategory].map((question) => (
                    <tr key={question?.questionId}>
                      <td>{question.title}</td>
                      {/* Frequency Dropdown */}
                      <td>
                        <select
                          className="form-select"
                          value={
                            triggerValues[question?.questionId]?.frequency || ""
                          }
                          onChange={(e) =>
                            handlePeriodChange(
                              question?.questionId,
                              "frequency",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Frequency</option>
                          {timePeriodOptions.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </td>
                      {/* Unit Dropdown */}
                      <td>
                        <select
                          className="form-select"
                          value={
                            triggerValues[question?.questionId]?.unit || ""
                          }
                          onChange={(e) =>
                            handleChange(
                              question?.questionId,
                              "unit",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Unit</option>
                          {unitOptions.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </td>
                      {/* Trigger Value Input */}
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter trigger value"
                          value={
                            triggerValues[question?.questionId]?.triggerValue ||
                            ""
                          }
                          onChange={(e) =>
                            handleChange(
                              question?.questionId,
                              "triggerValue",
                              e.target.value
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        : selectedCategory &&
          questions[selectedCategory] && (
            <div className="questions-container">
              <table className="table">
                <thead>
                  <tr>
                    <th>Question Title</th>
                    <th>Frequency</th>
                    <th>Unit</th>
                    <th>Trigger Value</th>
                  </tr>
                </thead>
                <tbody>
                
                  {questions
                    .find((item) => item.id === Number(selectedCategory))
                    .questions.map((question) =>
                      question.questionType === "tabular_question" ? (
                        <React.Fragment key={question?.questionId}>
                          <tr>
                            <td colSpan="4">
                              <p>
                                <strong>{question.title}</strong>
                              </p>
                            </td>
                          </tr>
                          {question.details
                            .slice()
                            .reverse()
                            .filter((detail) => detail.optionType === "row")
                            .map((row, rowIndex) =>
                              question.details
                                .slice()
                                .reverse()
                                .filter(
                                  (detail) => detail.optionType === "column"
                                )
                                .map((column, colIndex) => (
                                  <tr key={`${rowIndex}-${colIndex}`}>
                                    <td>{`${row.option} - ${column.option}`}</td>
                                    <td>
                                      <select
                                        className="form-select"
                                        value={
                                          triggerValues[question?.questionId]?.[
                                            rowIndex
                                          ]?.[colIndex]?.frequency || ""
                                        }
                                        onChange={(e) =>
                                          handleTabularChangeChange(
                                            question?.questionId,
                                            rowIndex,
                                            colIndex,
                                            "frequency",
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">
                                          Select Frequency
                                        </option>
                                        {timePeriodOptions.map(
                                          (option, index) => (
                                            <option
                                              key={index}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </td>
                                    <td>
                                      <select
                                        className="form-select"
                                        value={
                                          triggerValues[question?.questionId]?.[
                                            rowIndex
                                          ]?.[colIndex]?.unit || ""
                                        }
                                        onChange={(e) =>
                                          handleTabularChangeChange(
                                            question?.questionId,
                                            rowIndex,
                                            colIndex,
                                            "unit",
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">Select Unit</option>
                                        {unitOptions.map((option, index) => (
                                          <option key={index} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter trigger value"
                                        value={
                                          triggerValues[question?.questionId]?.[
                                            rowIndex
                                          ]?.[colIndex]?.triggerValue || ""
                                        }
                                        onChange={(e) =>
                                          handleTabularChangeChange(
                                            question?.questionId,
                                            rowIndex,
                                            colIndex,
                                            "triggerValue",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                ))
                            )}
                        </React.Fragment>
                      ) : (
                        <tr key={question?.questionId}>
                          <td>
                            <strong>{question.title}</strong>
                          </td>
                          <td>
                            <select
                              className="form-select"
                              value={
                                triggerValues[question?.questionId]
                                  ?.frequency || ""
                              }
                              onChange={(e) =>
                                handleTabularChangeChange(
                                  question?.questionId,
                                  "frequency",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Frequency</option>
                              {timePeriodOptions.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <select
                              className="form-select"
                              value={
                                triggerValues[question?.questionId]?.unit || ""
                              }
                              onChange={(e) =>
                                handleTabularChangeChange(
                                  question?.questionId,
                                  "unit",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Unit</option>
                              {unitOptions.map((option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter trigger value"
                              value={
                                triggerValues[question?.questionId]
                                  ?.triggerValue || ""
                              }
                              onChange={(e) =>
                                handleTabularChangeChange(
                                  question?.questionId,
                                  "triggerValue",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          )}
    </div>
  );
};

export default Triggers;
