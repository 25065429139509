import React from "react";
import EnergyConsumptionBar from "../Environment/EnergyConsumptionBar";
import ProductWiseSingleLoc from "./ProductWiseSingleLoc";
import Renewable from "../Environment/RenewableAndNoneRenew";
import EnergyConsumptionCard from "./FrameworkOne/TotalEnergyConsumption";
import EnergyConsumptionChart from "./FrameworkOne/EnergyConsumptionChart";
import CommonBarComponent from "./FrameworkOne/CommonBarComponent";
import TotalEnergySingLocMultTime from "./FrameworkOne/TotalEnergySingLocMultTime";
import RenewableAndNonRenewable from "./FrameworkOne/RenewableAndNonRenewable";
import ProductWiseStacked from "./FrameworkOne/ProductWiseStacked";
import EnergyConsumptionFourtyEight from "./Framework48/EnergyConsumptionFourtyEight";
import FourtyEightTotalEnergy from "./Framework48/FourtyEightTotalEnergy";
import ProductWiseFourtyEight from "./Framework48/ProductWiseFourtyEight";

const SingleLocMultTime = ({
  companyFramework,
  sectorQuestionAnswerDataForGraph,
  timePeriods,
  graphData,
  totalConsumptionRenewable,
  totalConsumptionNonRenewable,
  brief,
  locationOption,
  renewableEnergy,
  nonRenewableEnergy,
  keyTab,
  timePeriodValues,
}) => {

  return companyFramework.includes(1) ?
    (
      <div className="d-flex flex-column flex-space-between">
        <div
          className="d-flex flex-row flex-space-between"
          style={{ height: "120vh", marginBottom: "3%" }}
        >
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <div style={{ height: "48%" }}>
              <TotalEnergySingLocMultTime timePeriodValues={timePeriodValues} locationOption={locationOption} timePeriods={timePeriods} renewableEnergy={renewableEnergy} nonRenewableEnergy={nonRenewableEnergy} />

            </div>

            <div style={{ height: "48%" }}>
              <ProductWiseStacked title={"Product Wise Renewable Energy"} timePeriodValues={timePeriodValues} locationOption={locationOption} timePeriods={timePeriods} product={renewableEnergy} />

            </div>
          </div>
          <div
            className="secondhalfprogress"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <div style={{ height: "48%" }}>
              <RenewableAndNonRenewable timePeriodValues={timePeriodValues} locationOption={locationOption} timePeriods={timePeriods} renewableEnergy={renewableEnergy} nonRenewableEnergy={nonRenewableEnergy} />

            </div>
            <div style={{ height: "48%" }}>
              <ProductWiseStacked title={"Product Wise Non-Renewable Energy"} timePeriodValues={timePeriodValues} locationOption={locationOption} timePeriods={timePeriods} product={nonRenewableEnergy} />

            </div>
          </div>
        </div>

      </div>
    ) : (
      <div className="d-flex flex-column flex-space-between">
        <div
          className="d-flex flex-row flex-space-between"
          style={{ height: "60vh", marginBottom: "3%" }}
        >
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <div style={{ height: "100%" }}>
              <FourtyEightTotalEnergy brief={brief} locationOption={locationOption} timePeriods={timePeriods} timePeriodValues={timePeriodValues} />

            </div>

            <div style={{ height: "0%" }}>
              {/* <ProductWiseStacked   timePeriodValues={timePeriodValues} locationOption={locationOption}  timePeriods={timePeriods} product={renewableEnergy}/> */}

            </div>
          </div>
          <div
            className="secondhalfprogress"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <div style={{ height: "100%" }}>
              {/* <RenewableAndNonRenewable timePeriodValues={timePeriodValues} locationOption={locationOption}  timePeriods={timePeriods} renewableEnergy={renewableEnergy} nonRenewableEnergy={nonRenewableEnergy}/> */}
              <ProductWiseFourtyEight timePeriodValues={timePeriodValues} locationOption={locationOption} brief={brief} timePeriods={timePeriods} renewableEnergy={renewableEnergy} nonRenewableEnergy={nonRenewableEnergy} />

            </div>
            <div style={{ height: "0%" }}>
              {/* <ProductWiseStacked  timePeriodValues={timePeriodValues} locationOption={locationOption} timePeriods={timePeriods} product={nonRenewableEnergy} /> */}

            </div>
          </div>
        </div>
        {/* <div
      className="d-flex flex-row flex-space-between"
      style={{ height: "22vh", marginBottom: "3%" }}
    ></div> */}
      </div>
    );
};

export default SingleLocMultTime;
