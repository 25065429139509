import React, { useState, useEffect } from "react";
import config from "../../../../config/config.json";
import { apiCall } from "../../../../_services/apiCall";
import Excel from "./excel";
// import { aggregate } from "./aggregate";

export default function ParentExcel({ allAnswers, allQuestions }) {


  const [sourceLabels, setUserLabels] = useState({});
  const [sourceIds, setSourceIds] = useState([]);
  const [selectedSourceId, setSelectedSourceId] = useState(sourceIds.length > 1 ? sourceIds[1] : null);
  // const [aggregatedData, setAggregatedData] = useState([]);

  const [selectedModuleName, setSelectedModuleName] = useState("ALL");
  const moduleNames = Array.from(new Set(allQuestions.map(item => item.moduleName)));


  useEffect(() => {
    setSourceIds(
      Array.from(
        new Set(
          allAnswers.flatMap((item) => {
            if (sourceLabels[item.sourceId]) {
              return Object.keys(sourceLabels).filter((key) =>
                key.startsWith(item.sourceId.toString())
              );
            }
            return item.sourceId;
          })
        )
      )
    );
  }, [sourceLabels, allAnswers]);

  const getLocations = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
        {},
        {},
        "GET"
      );

      if (isSuccess && data?.data) {
        const labels = data.data.reduce((acc, item) => {
          if (item.id) {
            console.log(JSON.stringify(item));

            // If sublocations exist, add their data with unique keys
            if (item.subLocation && item.subLocation.length > 0) {
              item.subLocation.forEach((location, index) => {
                console.log("Sublocation forEach entered: " + JSON.stringify(location));
                const key = `${item.id}-${location.id}`; // Ensure unique keys by appending index
                const value = `${item.unitCode} - ${location.subLocation}`;
                console.log("Sublocation keys and values: " + key + " - " + value);
                acc[key] = value; // Add sublocation to the accumulator
              });
            }
            // Add the parent location to the accumulator
            acc[item.id] = item.unitCode;

          }
          return acc;
        }, {});

        setUserLabels(labels);
      } else {
        console.warn("getSource API call unsuccessful or returned no data");
      }
    } catch (error) {
      console.error("Error fetching source labels:", error);
    }
  };

  const computeAggregatedData = () => {
    // console.log("Aggregating data");

    // const invalidValues = new Set([
    //   "nil",
    //   "nill",
    //   "na",
    //   "not applicable",
    //   "notapplicable",
    //   "not-applicable",
    // ]);

    // const aggregated = aggregate(allAnswers);

    // console.log("Aggregated data:", aggregated);
    // setAggregatedData(aggregated);
  };




  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    if (selectedSourceId === "ALL") {
      computeAggregatedData();
    }
  }, [selectedSourceId, selectedModuleName]);

  // Filter data based on selected source and module name
  // Filter and group data based on selected source and module name
  const filteredData = allAnswers
    .filter((item) => {
      if (!selectedSourceId) {
        return;
      }
      // Find the corresponding question for the current item
      const currentQuestion = allQuestions?.find((question) => question.questionId === item.questionId);

      // Skip the item if no matching question is found
      if (!currentQuestion) {
        console.warn("No matching question found for item:", item);
        return false;
      }

      let matchesSourceId = false;

      if (item.subLocationId) {
        const sourceId = item.sourceId + "-" + item.subLocationId;
        matchesSourceId = selectedSourceId === "ALL" || sourceId === selectedSourceId;
        if (matchesSourceId) {
          //console.log("SourceId matched: " + item.sourceId)
        }
        else {
          console.log("Not Sublocation SourceId matched: " + sourceId + " and " + selectedSourceId)
        }
      } else {
        matchesSourceId = selectedSourceId === "ALL" || item.sourceId == selectedSourceId || item.sourceId === selectedSourceId;
        if (matchesSourceId) {
          //console.log("SourceId matched: " + item.sourceId)
        }
        else {
          console.log("Not Lot SourceId matched: " + item.sourceId + " and " + selectedSourceId)
        }
      }

      const matchesModuleName = selectedModuleName === "ALL" || currentQuestion.moduleName === selectedModuleName;

      // Attach `moduleName` for sorting purposes
      if (matchesSourceId && matchesModuleName) {
        item.moduleName = currentQuestion.moduleName; // Attach `moduleName` to the item
        return true;
      }
      return false;
    })
    .sort((a, b) => {
      // Sort by `moduleName` only if `selectedModuleName` is "ALL"
      console.log("selectedModuleName:  " + selectedModuleName);
      if (selectedModuleName === "ALL") {
        const moduleA = a.moduleName || "";
        const moduleB = b.moduleName || "";
        return moduleA.localeCompare(moduleB);
      }
      return 0; // No sorting needed if `selectedModuleName` is not "ALL"
    });

  return (
    <div>
      <label style={{ marginRight: "8px" }}>Location:</label>
      <select
        value={selectedSourceId !== null ? selectedSourceId : ""}
        onChange={(e) => setSelectedSourceId(e.target.value)} // Use the exact value as is
      >
        <option value="ALL">ALL</option>
        {sourceIds.map((id) => (
          <option key={id} value={id}>
            {sourceLabels[id] || `User ${id}`}
          </option>
        ))}

      </select>

      <label style={{ marginLeft: "16px", marginRight: "8px" }}>Module:</label>
      <select
        value={selectedModuleName !== null ? selectedModuleName : ""}
        onChange={(e) =>
          setSelectedModuleName(e.target.value ? (e.target.value === "ALL" ? "ALL" : e.target.value) : null)
        }
      >
        <option value="ALL">ALL</option>
        {moduleNames.map((name, index) => (
          <option key={index} value={name}>
            {name}
          </option>
        ))}
      </select>

      <div style={{ marginTop: "16px" }}>
        <Excel data={filteredData} allQuestions={allQuestions} />
      </div>
    </div>
  );
}
