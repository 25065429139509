import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import { apiCall } from "../../../../_services/apiCall";
import config from "../../../../config/config.json";
import Esgdownload from "../../Component/ESGDownload/esgdownload";
import TabsComponent from "../../../TabsComponent/tabs";

const Dashboard = (props) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const firstName = currentUser?.first_name;
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [financialYear, setFinancialYear] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [timePeriods, setTimePeriods] = useState({})
  const [locationOption, setLocationOption] = useState();
  const [framework, setFramework] = useState();
  const [toDate, setToDate] = useState("")
  const [keyTab, setKeyTab] = useState("compare")
  const [currentTab, setCurrentTab] = useState(0)

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [financialYearId, setFinancialYearId] = useState("");
  const [graphData, setGraphData] = useState("");
  const [sectorQuestionAnswerDataForGraph, setSectorQuestionAnswerDataForGraph] = useState([]);
  const [frameworkValue, setFrameworkValue] = useState([]);
  const [todaysActivities, setTodaysActivities] = useState([]);
  const getTotalTrainingData = async () => {
    if (financialYearId) {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getTotalTrainingData`,
        {},
        { financialYearId },
        "GET"
      );
      if (isSuccess && isMounted.current) {
        setGraphData(data?.data)
        setSectorQuestionAnswerDataForGraph(data?.sectorQuestionAnswer);

      }
    }

  };
  const fetchFrameworkApi = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFramework`,
      {},
      { type: "ALL", userId: JSON.parse(localStorage.getItem("user_temp_id")) }
    );
    if (isSuccess && isMounted.current) {
      setFrameworkValue(data?.data);
    }
  };

  const downloadFile = async (pdfFilePath, fileName) => {
    try {
      const response = await fetch(process.env.PUBLIC_URL + pdfFilePath);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 1000);
      setShow(false);
    } catch (error) {
      console.error(`Error fetching the PDF file (${fileName}):`, error);
    }
  };

  const downloadPdf = async (type) => {
    const pdfFilePath1 = "/BRSR.pdf";
    const pdfFilePath2 = "/BRSR Kennametal Report V1.4.docx";
    if (type === 'PDF') {
      await downloadFile(pdfFilePath1, "BRSR.pdf");
    } else {
      await downloadFile(pdfFilePath2, "BRSR Kennametal Report V1.4.docx");
    }
  };

  const todaysActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}todaysActivity`,
      {},
      {
        fromDate: fromDate,
        toDate: toDate,
        financialYearId: financialYearId
      },
      "GET"
    );
    if (isSuccess) {
      setTodaysActivities((data?.data).reverse());
    }
  };

  useEffect(() => {
    if (fromDate && toDate && financialYearId) {
      todaysActivity();
    }
  }, [fromDate, toDate, financialYearId]);

  useEffect(() => {  
    fetchFrameworkApi();
    getTotalTrainingData();
  }, [financialYearId]);

  return (
    <div className="d-flex flex-row mainclass" style={{ height: "100vh", overflow: "auto" }}>
      <div style={{ flex: "0 0 21%", position: "sticky", top: 0, zIndex: 999 }}>
        <Sidebar dataFromParent={props.location.pathname} />
      </div>
      <div style={{ flex: "1 1 79%" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 999 }}>
          <Header />
        </div>
        <div className="main_wrapper">
          <div className="inner_wraapper p-3">
            <div className="text-center mb-2">
              <h5 style={{ color: "black", paddingTop: "20px" }}>
                <p className="welcome">Welcome back, {firstName} </p>
              </h5>
              <div style={{ fontSize: "13px" }}>Monitor your report progress here</div>
            </div>
          </div>
          <div className="inner_wraapper" style={{ width: "100%", paddingTop: "10px", paddingBottom: "10px" }}>
            <Esgdownload currentTab={currentTab} setKeyTab={setKeyTab} keyTab={keyTab} framework={framework} frameworkValue={frameworkValue} setFramework={setFramework} setLocationOption={setLocationOption} setTimePeriods={setTimePeriods} setFinancialYearId={setFinancialYearId}
              setFromDate={setFromDate} setToDate={setToDate} financialYear={financialYear} setFinancialYear={setFinancialYear} show={show} handleClose={handleClose} downloadPdf={downloadPdf} downloadFile={downloadFile} setShow={setShow} financialYearId={financialYearId} />
          </div>
          <div className="tabs w-100 ">
            <TabsComponent
              keyTab={keyTab}
              setCurrentTab={setCurrentTab}
              setKeyTab={setKeyTab}
              framework={framework} fromDate={fromDate} toDate={toDate} financialYearId={financialYearId} locationOption={locationOption} timePeriods={timePeriods} graphData={graphData} frameworkValue={frameworkValue} sectorQuestionAnswerDataForGraph={sectorQuestionAnswerDataForGraph} 
              todaysActivities={todaysActivities}/>
          </div>
        </div>
      </div>
    </div>


  );
};

export default Dashboard;
