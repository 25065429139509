import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentWaste from "./TopComponentWaste";
import WasteGenerated from "./WasteGenerated";
import WasteRecovered from "./WasteRecovered";
import WasteDisposed from "./WasteDisposed";
import WasteComparison from "./WasteComparison";
import TotalWasteGeneratedByLoc from "./TotalWasteGeneratedByLoc";
import WasteDisposedMulti from "./WwasteDisposedMultiLoc";
import WasteRecoveredMulti from "./WasteRecoveredMulti";
import WasteRecTarget from "./WasteRecTraget";
import WasteCostAnalysis from "./WasteCostAnalysis";

import WwasteCostAnalysisSingle from "./WasteCostAnalysisSingle";
import WasteRecoveredSingle from "./WasteRecoveredSingle";
import WasteDisposalChart from "./WasteDisposalChart";
import WasteGeneratedSingle from "./WaterStorageTrackingUnit.jsx";
import WasteSingleLocSingleTime from "./WasteSingleLocSingleTime.jsx";
import WasteSingleLocMultTime from "./WasteSingleLocMultTime.jsx";
import WasteMultiLocMultiTime from "./FrameworkFourtyEight/WasteMultiLocMultiTimeGen.jsx";
import WasteDispMultLoc from "./FrameworkFourtyEight/WasteDispMultLoc.jsx";
import WasteDisposedMultiLocMultiTime from "./FrameworkFourtyEight/WasteDisposedMultiLocMultiTime.jsx";

const Waste = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  keyTab,
  frameworkValue,
}) => {
  const location = 2;
  const [lastWeekActivities, setLastWeekActivities] = useState({});
  const [totalSum, setTotalSum] = useState(0);
  const [totalSumTwo, setTotalSumTwo] = useState(0);
  const [companyFramework, setCompanyFramework] = useState([]);
  const [timePeriodValues, setTimePeriodValues] = useState([]);

  const [totalSumThree, setTotalSumThree] = useState(0);
  const [brief, setBrief] = useState()

  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };

  const lastWeekActivity = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
        {},
        {},
        "GET"
      );
      if (isSuccess) {
        const activityData = {
          "Total Waste Generated": {
            number: `${totalSum} MT`,
            questionId: [],
          },
          "Total Waste Disposed": {
            number: `${totalSumTwo} MT`,
            questionId: [],
          },
          "Total Waste Recovered": {
            number: `${totalSumThree} MT`,
            questionId: [],
          },
          message: "Good Evening, Sunil Kumar",
        };
        setLastWeekActivities(activityData);
      }
    } catch (error) {
      console.error('Error fetching last week activities:', error);
      // Handle the error as needed
    }
  };

  // Initialize states for different types of waste data
  const [matchedDataWaste, setMatchedDataWaste] = useState([]);
  const [wasteRecovered, setWasteRecovered] = useState([]);
  const [wasteDisposal, setWasteDisposal] = useState([]);
  const [locations, setLocations] = useState();
  const [quarters, setQuarters] = useState();

  const wasteSeries = [
    "Plastic",
    "E-Waste",
    "Biomedical",
    "Construction and demolition",
    "Battery",
    "Radioactive",
    "Other hazardous wastes",
    "Other non-hazardous wastes",
  ];

  const wasteSeriesTwo = [
    "Incineration",
    "Landfilling",
    "Other disposal operations",
  ];

  const recoverySeries = [
    "Re-cycled",
    "Re-used",
    "Other Recovery Options",
  ];

  function convertMixedData(mixedArray) {
    return mixedArray.map((data) => {
      if (Array.isArray(data.answer) && Array.isArray(data.answer[0])) {
        const flattenedAnswer = data.answer.flat();
        const summedValue = flattenedAnswer.reduce(
          (sum, value) => sum + (parseFloat(value) || 0),
          0
        );

        return {
          questionId: data.questionId,
          sourceId: data.sourceId,
          answer: {
            process: 1,
            readingValue: summedValue.toString(),
            unit: "KG",
          },
          title: data.title,
          question_details: data.question_details,
          formDate: data.formDate,
          toDate: data.toDate,
        };
      } else {
        return {
          ...data,
          answer: {
            ...data.answer,
            readingValue: data?.answer?.readingValue || "0",
          },
        };
      }
    });
  }

  useEffect(() => {
    // Safeguard against undefined graphData or timePeriods
    const newWaterType = graphData?.filter(item => item.title === "Waste Management") || [];
    const newWasteDischarge = graphData?.filter(item => item.title === "Waste Disposal") || [];
    const newWasteRecovery = graphData?.filter(item => item.title === "Waste Recovery") || [];
    const timePeriodsArray = Object.values(timePeriods || []);
    setTimePeriodValues(timePeriodsArray);

    // Update state with filtered data
    setWasteRecovered(newWasteRecovery);
    setWasteDisposal(newWasteDischarge);
    setMatchedDataWaste(newWaterType);



    // Filter matched data based on time periods
    const newMatchedDataWaste = newWaterType.filter(item =>
      timePeriodsArray.includes(item.formDate)
    );

    const newMatchedDataWasteDischarge = newWasteDischarge.filter(item =>
      timePeriodsArray.includes(item.formDate)
    );

    const wasteRecovery = newWasteRecovery.filter(item =>
      timePeriodsArray.includes(item.formDate)
    );

    const finalEnergy = newMatchedDataWaste.filter((item) =>
      locationOption.some((location) => location.id === item.sourceId)
    );

    const finalEnergyTwo = newMatchedDataWasteDischarge.filter((item) =>
      locationOption.some((location) => location.id === item.sourceId)
    );

    const finalEnergyThree = wasteRecovery.filter((item) =>
      locationOption.some((location) => location.id === item.sourceId)
    );



    setWasteDisposal(finalEnergyTwo);
    setMatchedDataWaste(finalEnergy);
    setWasteRecovered(finalEnergyThree);

    // Calculate total sum for waste management
    const aggregated = wasteSeries.reduce((acc, wasteType) => {
      acc[wasteType] = 0; // Initialize each waste type with 0
      return acc;
    }, {});

    let sum = 0;
    finalEnergy.forEach(item => {
      const answers = item.answer?.[0] || [];
      answers.forEach((value, index) => {
        if (wasteSeries[index]) {
          const numericValue = value === "NA" || !value ? 0 : parseFloat(value);
          aggregated[wasteSeries[index]] += numericValue;
          sum += numericValue; // Add to total sum
        }
      });
    });

    setTotalSum(sum);

    // Calculate total sum for waste disposal
    const aggregatedTwo = wasteSeriesTwo.reduce((acc, disposalType) => {
      acc[disposalType] = 0; // Initialize each disposal type with 0
      return acc;
    }, {});

    finalEnergyTwo.forEach(item => {
      const answers = item.answer?.[0] || [];
      answers.forEach((value, index) => {
        if (wasteSeriesTwo[index]) {
          const numericValue = value === "NA" || !value ? 0 : parseFloat(value);
          aggregatedTwo[wasteSeriesTwo[index]] += numericValue;
        }
      });
    });

    const seriesData = wasteSeriesTwo.map(disposalType => aggregatedTwo[disposalType] || 0);
    const totalSumTwo = seriesData.reduce((sum, value) => sum + value, 0);
    setTotalSumTwo(totalSumTwo);

    // Calculate total sum for waste recovery
    const aggregatedThree = recoverySeries.reduce((acc, recoveryType) => {
      acc[recoveryType] = 0; // Initialize each recovery type with 0
      return acc;
    }, {});

    finalEnergyThree.forEach(item => {
      const answers = item.answer?.[0] || [];
      answers.forEach((value, index) => {
        if (recoverySeries[index]) {
          const numericValue = value === "NA" || !value ? 0 : parseFloat(value);
          aggregatedThree[recoverySeries[index]] += numericValue;
        }
      });
    });

    const totalSumThree = Object.values(aggregatedThree).reduce((sum, value) => sum + value, 0);
    setTotalSumThree(totalSumThree);



    if (companyFramework && companyFramework.includes(48)) {
      setTimePeriodValues(timePeriodsArray);
      const valuesArray = locationOption
        ? locationOption.map((item) => item.unitCode || item.value)
        : [];

      const transformedKeys = Object.keys(timePeriods).map((key) =>
        key
      );

      // setSelection(view === "time" ? valuesArray[0] : transformedKeys[0]);
      setQuarters(transformedKeys);
      setLocations(valuesArray);

      const summary = {
        time: {},
        location: {},
      };

      if (locationOption) {
        locationOption.forEach((location) => {
          transformedKeys.forEach((quarter) => {
            summary.location[quarter] = {
              "Total non-hazardous solid waste generated (black category general waste)": new Array(locationOption.length).fill(0),
              "Total non-hazardous solid waste sent to landfill (construction waste/other waste to landfill)": new Array(locationOption.length).fill(0),
              "Total non-plastic packaging waste generated": new Array(locationOption.length).fill(0),
              "Total plastic packaging waste generated": new Array(locationOption.length).fill(0),
              "e-waste generated":
                new Array(locationOption.length).fill(0),
              "Total hazardous waste ( spent oil/lubricants etc)": new Array(
                locationOption.length
              ).fill(0),
              "Total metal scraps disposed":
                new Array(locationOption.length).fill(0),
              "e-waste disposed":
                new Array(locationOption.length).fill(0),

            };
          });
        });
      }

      if (transformedKeys) {
        transformedKeys.forEach((quarter) => {
          locationOption.forEach((location) => {
            summary.time[location.unitCode] = {
              "Total non-hazardous solid waste generated (black category general waste)": new Array(transformedKeys.length).fill(0),
              "Total non-hazardous solid waste sent to landfill (construction waste/other waste to landfill)": new Array(transformedKeys.length).fill(0),
              "Total non-plastic packaging waste generated": new Array(transformedKeys.length).fill(0),
              "Total plastic packaging waste generated": new Array(transformedKeys.length).fill(0),
              "Food Waste/Kitchen Waste": new Array(transformedKeys.length).fill(0),
              "e-waste generated":
                new Array(transformedKeys.length).fill(0),
              "Total hazardous waste ( spent oil/lubricants etc)": new Array(
                transformedKeys.length
              ).fill(0),
              "Total metal scraps disposed":
                new Array(transformedKeys.length).fill(0),
              "e-waste disposed":
                new Array(transformedKeys.length).fill(0),

            };
          });
        });
      }



      if (graphData) {
        const filteredData = graphData.filter(
          (item) =>
            item.questionId === 400 ||
            item.questionId === 401 ||
            item.questionId === 402 ||
            item.questionId === 404 ||
            item.questionId === 408 ||
            item.questionId === 406 ||
            item.questionId === 412 ||
            item.questionId === 413 ||
            item.questionId === 414

        );
        const convertedData = convertMixedData(filteredData);
        const timeKey = [];
        const locationKey = [];

        for (const period in summary.location) {
          timeKey.push(period);
        }

        for (const period in summary.time) {
          locationKey.push(period);
        }

        for (const location in summary.time) {
          const data = summary.time[location];
          for (const key in data) {
            for (let k = 0; k < summary.time[location][key].length; k++) {
              let time = timeKey[k];
              const obj = locationOption.find(
                (item) => item.unitCode === location
              );
              const lowerCaseKey = time;
              const formDate = timePeriods[lowerCaseKey];
              const filterData = convertedData.find(
                (item) =>
                  item.title === key &&
                  item.formDate === formDate &&
                  item.sourceId === obj.id
              );
              summary.time[location][key][k] =
                Number(filterData?.answer?.readingValue) || 0;
            }
          }
        }
        for (const time in summary.location) {
          const data = summary.location[time];
          for (const key in data) {
            for (let k = 0; k < summary.location[time][key].length; k++) {
              let location = locationKey[k];
              const obj = locationOption.find(
                (item) => item.unitCode === location
              );
              const lowerCaseKey = time;
              const formDate = timePeriods[lowerCaseKey];
              const filterData = convertedData.find(
                (item) =>
                  item.title === key &&
                  item.formDate === formDate &&
                  item.sourceId === obj.id
              );
              summary.location[time][key][k] =
                Number(filterData?.answer?.readingValue) || 0;
            }
          }
        }
      }

      setBrief(summary);

    }




  }, [graphData, timePeriods, companyFramework, keyTab, locationOption]);

  useEffect(() => {
    lastWeekActivity();
  }, [totalSum, totalSumTwo, totalSumThree]);

  useEffect(() => {
    if (Array.isArray(frameworkValue) && frameworkValue.length) {
      const frameworkId = frameworkValue.map((value) => value.id);
      setCompanyFramework(frameworkId);
    }
  }, [frameworkValue]);
  return (
    <div className="progress-container">
      {companyFramework &&
        companyFramework.length &&
        companyFramework.includes(1) && <div className="topcompo">
          {companyFramework &&
            companyFramework.length &&
            companyFramework.includes(1) && lastWeekActivities && (
              <TopComponentWaste
                lastWeekActivities={lastWeekActivities}
                icons={icons}
              />
            )}
        </div>}



      {/* {companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1) ?  
           <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "80vh", marginBottom: "3%" }}
          >
            <WasteGeneratedSingle
              matchedDataWaste={matchedDataWaste}
            />
          </div>

          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "50vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <WasteDisposalChart wasteDisposall={wasteDisposal}/>
            </div>
            <div className="secondhalfprogress">
              
              <div style={{ height: "50%" }}>
                <WasteRecoveredSingle
               wasteRecovered={wasteRecovered}
                />
              </div>
            </div>
          </div>
        </div> : 
        (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <TotalWasteGeneratedByLoc
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            />
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
         
            <WasteRecoveredMulti
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            />

         
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
         
            <WasteDisposedMulti
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            />

          
          </div>
          
        </div>
      )} */}
      {companyFramework &&
        companyFramework.length &&
        companyFramework.includes(1) ?
        (
          (timePeriodValues &&
            locationOption.length === 1 &&
            timePeriodValues.length === 1) ||
            (locationOption.length > 1 &&
              timePeriodValues.length === 1 &&
              keyTab === "combined") ? (
            <>
              <WasteSingleLocSingleTime
                graphData={graphData}
                keyTab={keyTab}
                locationOption={locationOption}
                brief={brief}
                timePeriods={timePeriods}
                timePeriodValues={timePeriodValues}
                matchedDataWaste={matchedDataWaste}
                wasteDisposal={wasteDisposal}
                wasteRecovered={wasteRecovered}



                companyFramework={companyFramework}
              />
            </>
          ) : (locationOption.length > 1 &&
            timePeriodValues.length > 1 &&
            keyTab === "combined") ||
            (locationOption.length > 1 && timePeriodValues.length === 1) ||
            (locationOption.length == 1 && timePeriodValues.length > 1) ? (
            <>
              <WasteSingleLocMultTime
                graphData={graphData}
                keyTab={keyTab}
                locationOption={locationOption}
                timePeriods={timePeriods}
                timePeriodValues={timePeriodValues}
                matchedDataWaste={matchedDataWaste}
                brief={brief}
                wasteDisposal={wasteDisposal}
                wasteRecovered={wasteRecovered}

                companyFramework={companyFramework}
              />
            </>
          ) : (
            <>

            </>
          )
        ) : (timePeriodValues &&
          locationOption.length === 1 &&
          timePeriodValues.length === 1) ||
          (locationOption.length > 1 &&
            timePeriodValues.length === 1 &&
            keyTab === "combined") ? (
          <>
            <WasteSingleLocSingleTime
              graphData={graphData}
              keyTab={keyTab}
              companyFramework={companyFramework}
              brief={brief}
            />
          </>
        ) : (locationOption.length > 1 &&
          timePeriodValues.length > 1 &&
          keyTab === "combined") ||
          (locationOption.length > 1 && timePeriodValues.length === 1) ||
          (locationOption.length == 1 && timePeriodValues.length > 1) ? (
          <>
            {brief && <WasteSingleLocMultTime
              graphData={graphData}
              keyTab={keyTab}
              brief={brief}
              locationOption={locationOption}
              timePeriods={timePeriods}
              timePeriodValues={timePeriodValues}

              companyFramework={companyFramework}
            />}
          </>
        ) : (
          timePeriodValues && (
            <div className="d-flex flex-column flex-space-between">
              <div
                className="d-flex flex-row flex-space-between"
                style={{ width: "100%", height: "70vh", marginBottom: "3%" }}
              >
                <div className="nothing" style={{ width: "49%" }}>
                  <WasteMultiLocMultiTime timePeriods={timePeriods} brief={brief} />


                </div>
                <div className="nothing" style={{ width: "50%", marginLeft: "1%" }}>
                  <WasteDisposedMultiLocMultiTime timePeriods={timePeriods} brief={brief} />


                </div>


              </div>



            </div>
          )
        )}
    </div>
  );
};

export default Waste;
