import React from "react";
import { StackedSafety } from "./FrameworkOne/StackedSafety";
import { StackedSafetyNonPerm } from "./FrameworkOne/StackedSafetyNonPermanant";
import SafetyBar from "./FrameworkOne/SafetyBar";
import SafetyBarFourtyEight from "./FrameworkFourtyEight/SafetyBarFourtyEight";

const SafetySingleLocSingleTime = ({
  companyFramework,

  timePeriods,
  dataOne,
  titleOne,
  dataThree,
  dataTwo,
  titleTwo,

  diversity,
  titleThree,
  titleFour,
  titleFive,
  brief,
  graphData,
  type,

  locationOption,

  keyTab,
  timePeriodValues,
}) => {
  return companyFramework.includes(1) ?
    (
      <div className="d-flex flex-column flex-space-between">
        <div
          className="d-flex flex-row flex-space-between"
          style={{ height: "50vh", marginBottom: "3%" }}
        >
          <div style={{ width: "100%" }}>
            <StackedSafety title={titleOne} data={dataOne} />
          </div>
        </div>
        <div
          className="d-flex flex-row flex-space-between"
          style={{ height: "50vh", marginBottom: "3%" }}
        >
          <div style={{ width: "100%" }}>
            <StackedSafetyNonPerm title={titleTwo} data={dataTwo} />
          </div>
        </div>
        <div
          className="d-flex flex-row flex-space-between"
          style={{ height: "30vh", marginBottom: "3%" }}
        >
          <SafetyBar type={type} title={titleThree} matchedDataWaste={dataThree} />

        </div>
      </div>
    ) : (
      <>
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "30vh", marginBottom: "3%" }}
          >
            <div
              className="firsthalfprogressenergy"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "45%",
              }}
            >
              <div style={{ height: "100%" }}>
                <SafetyBarFourtyEight brief={brief} />
              </div>

              <div style={{ height: "0%" }}></div>
            </div>
            <div
              className="secondhalfprogress"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "55%",
              }}
            >
              <div style={{ height: "65%" }}></div>
              <div style={{ height: "0%" }}></div>
            </div>
          </div>
        </div>
      </>
    );
};

export default SafetySingleLocSingleTime;
